import { createSlice } from "@reduxjs/toolkit";

export const generalDataSlice = createSlice({
  name: "generalData",
  initialState: {
    currentTab: "CCV",
    listingView: "preparation",
    searchString: "",
    orderSelectedRows: [],
    totalSelectedRows: 0,
    odOrderId: 0,
    odSelectedRows: [],
    odApiData: {},
    sChangeCount: 0,
    searchsChangeCount: 0,
    orderErrorRows: []
  },
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setListingView: (state, action) => {
      state.listingView = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    setOrderSelectedRows: (state, action) => {
      if (state.orderSelectedRows.includes(action.payload)) {
        state.orderSelectedRows = state.orderSelectedRows.filter(
          (orderId) => orderId !== action.payload
        );
      } else {
        state.orderSelectedRows.push(action.payload);
      }
      state.totalSelectedRows = state.orderSelectedRows.length;
    },
    resetOrderSelectedRows: (state, action) => {
      state.orderSelectedRows = [];
      state.totalSelectedRows = 0;
    },
    setOrderErrorRows: (state, action) => {
      state.orderErrorRows = action.payload;
    },
    resetOrderErrorRows: (state, action) => {
      state.orderErrorRows = [];
    },
    setOdSelectedRows: (state, action) => {
      if (state.odOrderId === 0) {
        state.odOrderId = action.payload.orderId;
        if (state.odSelectedRows.includes(action.payload.odId)) {
          state.odSelectedRows = state.odSelectedRows.filter(
            (odId) => odId !== action.payload.odId
          );
          delete state.odApiData[action.payload.odId];
          if (state.odSelectedRows.length <= 0) state.odOrderId = 0;
        } else {
          state.odSelectedRows.push(action.payload.odId);
          state.odApiData[action.payload.odId] = {};
          /* let isActiveVoucher = state.odApiData.length > 0 && state.odApiData[state.odOrderId].length > 0 && state.odApiData[state.odOrderId]["voucher"] === "undefined" ? 0 : 1;
          let isActiveRestock = state.odApiData.length > 0 && state.odApiData[state.odOrderId].length > 0 && state.odApiData[state.odOrderId]["restock"] === "undefined" ? 0 : 1;
          let isActiveCredit = state.odApiData.length > 0 && state.odApiData[state.odOrderId].length > 0 && state.odApiData[state.odOrderId]["credit_slip"] === "undefined" ? 0 : 1;
          state.odApiData[state.odOrderId] = {};
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId]["amount"] =
            action.payload.odUnitPrice * action.payload.odQty;
          state.odApiData[state.odOrderId]["shipping_amount"] = 0;
          state.odApiData[action.payload.odId]["voucher_refund_type"] = 0;
          state.odApiData[action.payload.odId]["orderId"] = state.odOrderId;
          state.odApiData[action.payload.odId]["orderDetailId"] =
            action.payload.odId;
          state.odApiData[action.payload.odId]["quantity"] = parseInt(
            action.payload.odQty
          );
          state.odApiData[state.odOrderId]["voucher"] = 0;
          state.odApiData[state.odOrderId]["restock"] = 0;
          state.odApiData[state.odOrderId]["credit_slip"] = 1; */
        }
      } else if (state.odOrderId === action.payload.orderId) {
        if (state.odSelectedRows.includes(action.payload.odId)) {
          state.odSelectedRows = state.odSelectedRows.filter(
            (odId) => odId !== action.payload.odId
          );
          delete state.odApiData[action.payload.odId];
          if (state.odApiData.length <= 0) {
            delete state.odApiData[state.odOrderId];
          }
          if (state.odSelectedRows.length <= 0) state.odOrderId = 0;
        } else {
          state.odSelectedRows.push(action.payload.odId);
          state.odApiData[action.payload.odId] = {};
          /* let isActiveVoucher = state.odApiData[state.odOrderId]["voucher"] === "undefined" ? 0 : 1;
          let isActiveRestock = state.odApiData[state.odOrderId]["restock"] === "undefined" ? 0 : 1;
          let isActiveCredit = state.odApiData[state.odOrderId]["credit_slip"] === "undefined" ? 0 : 1;
          state.odApiData[state.odOrderId] = {};
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId]["amount"] =
            action.payload.odUnitPrice;
          state.odApiData[state.odOrderId]["shipping_amount"] = 0;
          state.odApiData[action.payload.odId]["voucher_refund_type"] = 0;
          state.odApiData[action.payload.odId]["orderId"] = state.odOrderId;
          state.odApiData[action.payload.odId]["orderDetailId"] = action.payload.odId;
          state.odApiData[action.payload.odId]["quantity"] = 1;
          state.odApiData[state.odOrderId]["voucher"] = 0;
          state.odApiData[state.odOrderId]["restock"] = 0;
          state.odApiData[state.odOrderId]["credit_slip"] = 1; */
        }
      } else {
        state.odSelectedRows = [];
        state.odApiData = {};
        state.odOrderId = action.payload.orderId;
        if (state.odSelectedRows.includes(action.payload.odId)) {
          state.odSelectedRows = state.odSelectedRows.filter(
            (odId) => odId !== action.payload.odId
          );
          delete state.odApiData[action.payload.odId];
          if (state.odSelectedRows.length <= 0) state.odOrderId = 0;
        } else {
          state.odSelectedRows.push(action.payload.odId);
          state.odApiData[action.payload.odId] = {};
          /* let isActiveVoucher = typeof state.odApiData[state.odOrderId] === "undefined" ? 0 : 1;
          let isActiveRestock = typeof state.odApiData[state.odOrderId] === "undefined" ? 0 : 1;
          let isActiveCredit = typeof state.odApiData[state.odOrderId] === "undefined" ? 0 : 1;
          state.odApiData[state.odOrderId] = {};
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId]["amount"] =
            action.payload.odUnitPrice;
          state.odApiData[state.odOrderId]["shipping_amount"] = 0;
          state.odApiData[action.payload.odId]["voucher_refund_type"] = 0;
          state.odApiData[action.payload.odId]["orderId"] = state.odOrderId;
          state.odApiData[action.payload.odId]["orderDetailId"] =
            action.payload.odId;
          state.odApiData[action.payload.odId]["quantity"] = 1;
          state.odApiData[state.odOrderId]["voucher"] = 0;
          state.odApiData[state.odOrderId]["restock"] = 0;
          state.odApiData[state.odOrderId]["credit_slip"] = 1; */
        }
      }
    },
    resetOdSelectedRows: (state, action) => {
      state.odOrderId = 0;
      state.odSelectedRows = [];
      state.odApiData = {};
    },
    setOdqty: (state, action) => {
      state.odApiData[action.payload.odId]["quantity"] = parseInt(
        action.payload.quantity
      );
      /* state.odApiData[action.payload.odId]["amount"] = parseFloat(
        action.payload.amount
      ).toFixed(2); */
    },
    setOdAmount: (state, action) => {
      state.odApiData[action.payload.odId]["amount"] = parseFloat(
        action.payload.amount
      ).toFixed(2);
    },
    setOdVoucher: (state, action) => {
      state.odApiData[state.odOrderId]["voucher"] = state.odApiData[
        state.odOrderId
      ]["voucher"]
        ? 0
        : 1;
    },
    setOdCreditSlip: (state, action) => {
      state.odApiData[state.odOrderId]["credit_slip"] = state.odApiData[
        state.odOrderId
      ]["credit_slip"]
        ? 0
        : 1;
    },
    setOdRestock: (state, action) => {
      state.odApiData[state.odOrderId]["restock"] = state.odApiData[
        state.odOrderId
      ]["restock"]
        ? 0
        : 1;
    },
    setShippingAmount: (state, action) => {
      state.odApiData[state.odOrderId]["shipping_amount"] = action.payload;
    },
    setPartialRefundType: (state, action) => {
      let rfType = action.payload.rfType;
      switch (rfType) {
        case "1":
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          break;
        case "2":
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          break;
        case "3":
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 1;
          break;
        default:
          state.odApiData[action.payload.odId][
            "quantity_" + action.payload.odId
          ] = 1;
          state.odApiData[action.payload.odId][
            "refundquantity_" + action.payload.odId
          ] = 0;
          state.odApiData[action.payload.odId][
            "zeroquantity_" + action.payload.odId
          ] = 0;
          break;
      }
    },
    setSChangeCount: (state, action) => {
      state.sChangeCount = state.sChangeCount + 1;
    },
    setSearchSChangeCount: (state, action) => {
      state.searchsChangeCount = state.searchsChangeCount + 1;
    },
    resetGeneralData: (state, action) => {
      state.currentTab = "CCV";
      state.listingView = "preparation";
      state.searchString = "";
      state.orderSelectedRows = [];
      state.totalSelectedRows = 0;
      state.odSelectedRows = [];
      state.odOrderId = 0;
      state.odApiData = {};
      state.sChangeCount = 0;
      state.searchsChangeCount = 0;
      state.orderErrorRows = [];
    },
  },
});

export const {
  setCurrentTab,
  setListingView,
  resetGeneralData,
  resetOrderSelectedRows,
  setSearchString,
  setOrderSelectedRows,
  setOdSelectedRows,
  setPartialRefundType,
  resetOdSelectedRows,
  setOdqty,
  setOdAmount,
  setOdVoucher,
  setOdCreditSlip,
  setOdRestock,
  setShippingAmount,
  setSChangeCount,
  setSearchSChangeCount,
  setOrderErrorRows,
  resetOrderErrorRows
} = generalDataSlice.actions;
export const selectCurrentTab = (state) => state.currentTab;
export const selectListingView = (state) => state.listingView;
export const selectSearchString = (state) => state.searchString;
export const selectOrderSelectedRows = (state) => state.selectedRows;
export const selectTotalSelectedRows = (state) => state.totalSelectedRows;
export const selectOdSelectedRows = (state) => state.odSelectedRows;
export const selectOrderErrorRows = (state) => state.orderErrorRows;
export const selectOdOrderId = (state) => state.odOrderId;
export default generalDataSlice.reducer;
