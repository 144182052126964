import { Container, Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import EmptyOrder from "../assets/img/empty-order.png";
const NoOrdersList = () => {
  return (
    <Container fluid className="p-0">
      <div className="row">
        <div className="col-md-12">
          <div className="commande-main mb-5 mt-0">
            <div className="commande-main-list">
              <Container fluid className="p-0">
                <Row className="show-grid">
                    <Col md={12} className="mt-5">
                      <Image src={EmptyOrder} thumbnail width={100} height={120}/>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={12} className="mb-3 mt-5">
                      <h4>Gérez vos commandes</h4>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={4}></Col>
                    <Col md={4} className="d-flex justify-content-between mb-5 lh-base">
                          Retrouvez toutes vos commandes sur cette page et accédez à leurs principales informations en un clic : vérifiez le total du panier, le moyen de paiement, les détails de livraison, ou si la commande a été passée par un nouveau client. Vous pouvez même mettre à jour le statut de la commande depuis la liste !
                    </Col>
                    <Col md={4}></Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NoOrdersList;
