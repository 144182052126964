const { createSlice } = require("@reduxjs/toolkit");

export const riekerSlice = createSlice({
  name: "rieker",
  initialState: {
    idWarehouse: "",
    pagination: 35,
    currentPage: 1,
    currentTab: "preparation",
    totalOrders: 0,
    totalPrepOrders: 0,
    newOrders: 0,
    newPrepOrders: 0,
    activeTab: 0,
  },
  reducers: {
    setRiekerWarehouse: (state, action) => {
      state.idWarehouse = action.payload;
      localStorage.setItem("riekerwarehouse", state.idWarehouse);
    },
    setRiekerPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setRiekerCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRiekerCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setRiekerTotalOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newOrders = 0;
      }
      if (state.totalOrders > 0) {
        let prevTotalOrders = state.totalOrders;
        state.totalOrders = action.payload;
        state.newOrders = Math.max(
          state.newOrders + (state.totalOrders - prevTotalOrders),
          0
        );
      } else {
        state.totalOrders = action.payload;
      }
    },
    setRiekerTotalPrepOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newPrepOrders = 0;
      }
      if (state.totalPrepOrders > 0) {
        let prevTotalPrepOrders = state.totalPrepOrders;
        state.totalPrepOrders = action.payload;
        state.newPrepOrders = Math.max(
          state.newPrepOrders + (state.totalPrepOrders - prevTotalPrepOrders),
          0
        );
      } else {
        state.totalPrepOrders = action.payload;
      }
    },
    setRiekerNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    setRiekerActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetRiekerState: (state, action) => {
      state.idWarehouse = "";
      state.pagination = 35;
      state.currentPage = 1;
      state.currentTab = "preparation";
      state.totalOrders = 0;
      state.totalPrepOrders = 0;
      state.newOrders = 0;
      state.newPrepOrders = 0;
      state.activeTab = 1;
    },
  },
});

export const {
  setRiekerWarehouse,
  setRiekerPagination,
  setRiekerCurrentPage,
  setRiekerCurrentTab,
  setRiekerTotalOrders,
  setRiekerTotalPrepOrders,
  setRiekerNewOrders,
  setRiekerActiveTab,
  resetRiekerState,
} = riekerSlice.actions;
export const selectRiekerWarehouse = (state) => state.idWarehouse;
export const selectRiekerPagination = (state) => state.pagination;
export const selectRiekerCurrentPage = (state) => state.currentPage;
export const selectRiekerCurrentTab = (state) => state.currentTab;
export default riekerSlice.reducer;
