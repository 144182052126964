const { createSlice } = require("@reduxjs/toolkit");

export const ccvSlice = createSlice({
  name: "ccv",
  initialState: {
    idWarehouse: "",
    pagination: 35,
    currentPage: 1,
    currentTab: "preparation",
    totalOrders: 0,
    totalPrepOrders: 0,
    newOrders: 0,
    newPrepOrders: 0,
    totalZalandoOrder: 0,
    activeTab: 1,
    totalPrepSiteOrders: 0,
    prepSiteOrders: 0,
  },
  reducers: {
    setCcvWarehouse: (state, action) => {
      state.idWarehouse = action.payload;
      localStorage.setItem("ccvwarehouse", state.idWarehouse);
    },
    setCcvPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setCcvCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCcvCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setCcvTotalOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newOrders = 0;
      }
      if (state.totalOrders > 0) {
        let prevTotalOrders = state.totalOrders;
        state.totalOrders = action.payload;
        state.newOrders = Math.max(
          state.newOrders + (state.totalOrders - prevTotalOrders),
          0
        );
      } else {
        state.totalOrders = action.payload;
      }
    },
    setCcvTotalPrepOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newPrepOrders = 0;
      }
      if (state.totalPrepOrders > 0) {
        let prevTotalPrepOrders = state.totalPrepOrders;
        state.totalPrepOrders = action.payload;
        state.newPrepOrders = Math.max(
          state.newPrepOrders + (state.totalPrepOrders - prevTotalPrepOrders),
          0
        );
      } else {
        state.totalPrepOrders = action.payload;
      }
    },
    setCcvTotalPrepSiteOrders: (state, action) => {
      state.totalPrepSiteOrders = action.payload;
    },
    setCcvPrepSiteOrders: (state, action) => {
      state.prepSiteOrders = action.payload;
    },
    setCcvTotalZalandoOrders: (state, action) => {
      state.totalZalandoOrder = action.payload;
      state.totalPrepSiteOrders = parseInt(state.prepSiteOrders) + parseInt(state.totalZalandoOrder);
    },
    setCcvNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    setCcvActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetCcvState: (state, action) => {
      state.idWarehouse = "";
      state.pagination = 35;
      state.currentPage = 1;
      state.currentTab = "preparation";
      state.totalOrders = 0;
      state.totalPrepOrders = 0;
      state.newOrders = 0;
      state.newPrepOrders = 0;
      state.activeTab = 1;
    },
  },
});

export const {
  setCcvWarehouse,
  setCcvPagination,
  setCcvCurrentPage,
  setCcvCurrentTab,
  setCcvTotalOrders,
  setCcvTotalPrepOrders,
  setCcvTotalZalandoOrders,
  setCcvTotalPrepSiteOrders,
  setCcvPrepSiteOrders,
  setCcvNewOrders,
  setCcvActiveTab,
  resetCcvState,
} = ccvSlice.actions;
export const selectCcvWarehouse = (state) => state.idWarehouse;
export const selectCcvPagination = (state) => state.pagination;
export const selectCcvCurrentPage = (state) => state.currentPage;
export const selectCcvCurrentTab = (state) => state.currentTab;
export default ccvSlice.reducer;
