import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/Login";
import Orders from "./pages/Orders";
import { SearchProvider } from "./helper/context/searchContext";
import { OdProvider } from "./helper/context/odContext";
import Toast from "./helper/toastHelper";

function App() {
  return (
    <SearchProvider>
      <OdProvider>
        <div className="App">
          <Toast />
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route exact path="/" element={<Orders />} />
              <Route exact path="/orders" element={<Orders />} />
            </Route>
            <Route exact path="/login" element={<Login />} />
          </Routes>
        </div>
      </OdProvider>
    </SearchProvider>
  );
}
export default App;
