import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userName: "",
    isLoggedIn: false,
    allowedSites: "",
    jwtToken: "",
    userId: 0,
  },
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload.name;
      state.userId = action.payload.userId;
      localStorage.setItem("username", state.userName);
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    removeUserName: (state, action) => {
      state.userName = "";
      localStorage.removeItem("username");
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
      localStorage.setItem("isLoggedIn", state.isLoggedIn);
    },
    removeIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
      localStorage.removeItem("isLoggedIn");
    },
    setAllowedSites: (state, action) => {
      let allowed_sites = action.payload.split(",");
      let final_sites = [];
      allowed_sites.forEach((allowed_site) => {
        switch (allowed_site) {
          case "1":
            final_sites.push("CCV");
            break;
          case "2":
            final_sites.push("MMax");
            break;
          case "3":
            final_sites.push("Mustang");
            break;
          case "4":
            final_sites.push("Rieker");
            break;
          case "5":
            final_sites.push("Rdh");
            break;
          default:
            final_sites.push("");
        }
      });
      state.allowedSites = final_sites.toString();
    },
    logoutEmp: (state, action) => {
      state.allowedSites = "";
      state.isLoggedIn = false;
      state.userName = "";
      state.jwtToken = "";
      state.userId = 0;
    },
  },
});

export const {
  setUserName,
  removeUserName,
  setJwtToken,
  setIsLoggedIn,
  removeIsLoggedIn,
  setAllowedSites,
  logoutEmp,
} = authSlice.actions;
export const selectUser = (state) => state.userName;
export const selectUserId = (state) => state.userId;
export const selectIsLoggedIn = (state) => state.isLoggedIn;
export const selectAllowedSites = (state) => state.allowedSites;
export const selectJwtToken = (state) => state.jwtToken;
export default authSlice.reducer;
