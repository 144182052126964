import mustangSlice, {
  setMustangWarehouse,
  setMustangPagination,
  setMustangCurrentPage,
  setMustangCurrentTab,
  setMustangTotalOrders,
  setMustangTotalPrepOrders,
  setMustangNewOrders,
  selectMustangWarehouse,
  selectMustangPagination,
  selectMustangCurrentPage,
  selectMustangCurrentTab,
  setMustangActiveTab,
  resetMustangState,
} from "./mustangSlice";

export {
  setMustangWarehouse,
  setMustangPagination,
  setMustangCurrentPage,
  setMustangCurrentTab,
  setMustangTotalOrders,
  setMustangTotalPrepOrders,
  setMustangNewOrders,
  selectMustangWarehouse,
  selectMustangPagination,
  selectMustangCurrentPage,
  selectMustangCurrentTab,
  setMustangActiveTab,
  resetMustangState,
};

export default mustangSlice;
