import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
};

export const showToast = (message, type, autoClose = 1000) => {
  toast[type](message, {
    position: "top-center",
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export default Toast;
