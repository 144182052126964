import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { GetShippingLabel } from "../helper/apiHelper";
import { useSelector } from "react-redux";
import { SearchContext } from "../helper/context/searchContext";

const PdfGenerator = ({ currentTab}) => {
    const selectedRows = useSelector(
        (state) => state.generalData.orderSelectedRows
    );
    const { toggleLoader } = useContext(SearchContext);
    const generatePDF = async () => {
        toggleLoader(true);
        if(currentTab === "CCV" || currentTab === "CCVZalando") {
            const response = await GetShippingLabel(currentTab,
                "test_picking.php",
                [{ checkbox: selectedRows.toString(), type: "0" }]
            );
            const orders = response.data.orders;
            const dateSession = response.data.date_session;
            const hasLocation = response.data.hasLocation;
            const ps15x = true;
            
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: 'a4',
            });
        
            doc.text(`Commandes ${dateSession}`, 20, 20);
        
            const tableColumn = [
                'ID',
                ...(ps15x ? ['Référence'] : []),
                'Image',
                'Référence du produit',
                ...(hasLocation ? ['Emplacement'] : []),
                'Taille',
                'Secteur',
                'Client',
                'Adresse',
                'Nom du produit',
                'Qté',
                'Saison',
                "Prix d'origine",
                'Prix de vente',
                'Poids',
                'Cadeaux',
                'Commentaire',
            ];
        
            const tableRows = [];
        
            orders.forEach((order) => {
                const productQuantity = parseInt(order[9], 10);
        
                for (let i = 1; i <= productQuantity; i++) {
                    const orderRow = [
                        i === 1 ? order[0] : '',
                        ...(ps15x ? [i === 1 ? order[1] : ''] : []),
                        { imageUrl: order[2], width: 39, height: 45 },
                        order[3],
                        ...(hasLocation ? [order[15]] : []),
                        order[4],
                        order[5],
                        order[6],
                        order[7],
                        order[8],
                        { content: '1', styles: { fontStyle: 'bold', textColor: order[9] > 1 ? 'red' : 'black' } },
                        order[12],
                        order[13],
                        { content: order[14], styles: { textColor: 'red' } },
                        order[10],
                        order[11] === 1 ? 'OUI' : '',
                        '',
                    ];
        
                    tableRows.push(orderRow);
                }
            });
        
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                rowPageBreak: 'avoid',
                styles: {
                    fontSize: 8,
                    textColor: 'black',
                    lineWidth: 0.5,
                    lineColor: 'black',
                },
                headStyles: {
                    fillColor: '#FFFACD',  
                    textColor: 'black',
                    lineWidth: 0.5,
                    lineColor: 'black',
                },
                columnStyles: {
                    0: { halign: 'center', cellWidth: 40 },
                    2: { halign: 'center' },
                    4: { cellWidth: hasLocation ? '' : 25 },
                    ...(hasLocation ? {5: {cellWidth: 25}} : {}),
                    7: { cellWidth: hasLocation ? '' : 30 },
                    8: { cellWidth: hasLocation ? 30 : 50 },
                    ...(hasLocation ? {9: {cellWidth: 50}} : {}),
                    10: { cellWidth: hasLocation ? '' : 30 },
                    ...(hasLocation ? {11: {cellWidth: 30}} : {}),
                    12: { cellWidth: hasLocation ? '' : 25 },
                    ...(hasLocation ? {13: {cellWidth: 25}} : {}),
                    ...(ps15x ? { 1: { cellWidth: hasLocation ? 60 : 60 } } : {}),
                    [ps15x ? 2 : 1]: { cellWidth: hasLocation ? 60 : 60 },
                    ...(hasLocation ? { [ps15x ? 3 : 2]: { cellWidth: 65 } } : {}),
                },
                didDrawCell: (data) => {
                    const { column, cell } = data;
                    if (column.index === (ps15x ? 2 : 1) && cell.raw?.imageUrl) {
                        doc.addImage(cell.raw.imageUrl, 'JPEG', cell.x + 4, cell.y + 2, cell.raw.width, cell.raw.height);
                    }
                },
            });
        
            doc.save(`ListingOrder_${Date.now()}.pdf`);
        } else {
            const response = await GetShippingLabel(currentTab,
                "download_product_data.php",
                [{ checkbox: selectedRows.toString(), type: "0" }]
            );
            const fileURL = response.data;
            window.open(fileURL, "PRINT");
        }
        toggleLoader(false);
    };

    return (
        <div>
            <Button onClick={generatePDF}>Télécharger le tableau de picking</Button>
        </div>
    );
};

export default PdfGenerator;
