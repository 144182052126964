import React, { useContext, useState } from "react";
import Headers from "../components/HeaderTop";
import LeftContent from "../components/LeftContent";
import RightContent from "../components/RightContent";
import PageOverlayLoader from "../components/PageOverlayLoader";
import { useDispatch, useSelector } from "react-redux";
import { CurrentTab } from "../helper/generalHelper";
import { setCcvCurrentPage } from "../store/site/ccv/ccvSlice";
import { setRiekerCurrentPage } from "../store/site/rieker/riekerSlice";
import { setMmaxCurrentPage } from "../store/site/mmax/mmaxSlice";
import { setMustangCurrentPage } from "../store/site/mustang/mustangSlice";
import { resetOrderSelectedRows } from "../store/generaldata/generalDataSlice";
import { SearchContext } from "../helper/context/searchContext";

const Orders = () => {
  const allowedSites = useSelector((state) => state.auth.allowedSites);
  const [pageSize, setPageSize] = useState(35);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const dispatch = useDispatch();
  const currentSite = CurrentTab();
  const {searchLoader, isSearchActive, setSearchSortName, setSearchSortDirection } = useContext(SearchContext);

  const handleCCVSorting = (sort, sortBy) => {
    if(isSearchActive) {
      setSearchSortName(sort);
      setSearchSortDirection(sortBy);
    } else {
      setSortName(sort);
      setSortDirection(sortBy);
    }    
  }

  const handlePageSize = (selectedPage) => {
    dispatch(resetOrderSelectedRows());
    const siteActionName =
      currentSite.charAt(0).toUpperCase() + currentSite.slice(1).toLowerCase();
    switch (siteActionName) {
      case "Ccv":
        dispatch(setCcvCurrentPage(1));
        break;
      case "Rieker":
        dispatch(setRiekerCurrentPage(1));
        break;
      case "Mmax":
        dispatch(setMmaxCurrentPage(1));
        break;
      case "Mustang":
        dispatch(setMustangCurrentPage(1));
        break;
      default:
        // Handle the case when currentSite doesn't match any known site
        break;
    }
    setPageSize(selectedPage);
  };

  const handlePageNumber = (e) => {
    setPageNumber(e.target.value);
  }

  return (
    <>
      <Headers />
      <section className="dashboard-main">
        <div className="container-fluid">
          <div className="row">
          {searchLoader && (
            <PageOverlayLoader/>
          )}
            <LeftContent
              allowedSites={allowedSites}
            />
            <RightContent
              pageSize={pageSize}
              handlePageSize={handlePageSize}
              pageNumber = {pageNumber}
              handlePageNumber = {handlePageNumber}
              sortName = {sortName}
              sortDirection = {sortDirection}
              handleCCVSorting = {handleCCVSorting}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;
