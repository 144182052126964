import React, { useState, useEffect, useContext } from "react";
import OrdersList from "./OrdersList";
import SearchForm from "./SearchForm";
import ActionButtons from "./ActionButtons";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCcvCurrentPage,
  setCcvTotalOrders,
  setCcvTotalPrepOrders,
  setCcvTotalZalandoOrders,
  setCcvPrepSiteOrders,
} from "../store/site/ccv/ccvSlice";
import GetWarehouseID from "../helper/warehouseHelper";
import NoOrdersList from "./NoOrdersList";
import AuthChecker from "../helper/authCheckerHelper";
import LoginPopUp from "./LoginPopUp";
import { CurrentTab } from "../helper/generalHelper";
import {
  setRiekerCurrentPage,
  setRiekerTotalOrders,
  setRiekerTotalPrepOrders,
} from "../store/site/rieker/riekerSlice";
import {
  setMustangCurrentPage,
  setMustangTotalOrders,
  setMustangTotalPrepOrders,
} from "../store/site/mustang/mustangSlice";
import {
  setMmaxCurrentPage,
  setMmaxTotalOrders,
  setMmaxTotalPrepOrders,
} from "../store/site/mmax/mmaxSlice";
import TabNewOrderCount from "../helper/tabNewOrderCountHelper";
import {
  resetGeneralData,
  resetOrderSelectedRows,
  setListingView,
} from "../store/generaldata/generalDataSlice";
import { GetOrders } from "../helper/apiHelper";
import { SearchContext } from "../helper/context/searchContext";
import { showToast } from "../helper/toastHelper";
import { OdContext } from "../helper/context/odContext";
import { persistor } from "../app/store";
import { setRdhCurrentPage, setRdhTotalOrders, setRdhTotalPrepOrders } from "../store/site/rdh/rdhSlice";


const RightContent = ({
  pageSize,
  handlePageSize,
  pageNumber,
  handlePageNumber,
  sortName,
  sortDirection,
  handleCCVSorting,
}) => {
  const { setSearchActive, setSearchText, toggleLoader, setDateSearch, setSelectedRange } = useContext(SearchContext);
  const { setTempOdSelected } = useContext(OdContext);
  const currentTab = CurrentTab();
  let listing = useSelector((state) => state.generalData.listingView);
  const [isAuthorizedUser, setAuthorizedUser] = useState(true);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const sChangeCount = useSelector((state) => state.generalData.sChangeCount);
  const handleListing = (listing) => {
    setSearchActive(false);
    setDateSearch(false);
    setSelectedRange([null, null]);
    const elements = document.querySelectorAll(".rs-picker-toggle-clean");
    elements.forEach(element => {
      element.click();
    });
    setSearchText("");
    dispatch(setListingView(listing));
    dispatch(resetOrderSelectedRows());
    dispatch(setCcvCurrentPage(1));
    dispatch(setRiekerCurrentPage(1));
    dispatch(setMmaxCurrentPage(1));
    dispatch(setMustangCurrentPage(1));
    dispatch(setRdhCurrentPage(1));
  };
  const ccv_warehouse_id = GetWarehouseID("ccv");
  const ccvPageNumber = useSelector((state) => state.ccv.currentPage);
  const rieker_warehouse_id = GetWarehouseID("rieker");
  const riekerPageNumber = useSelector((state) => state.rieker.currentPage);
  const mustang_warehouse_id = GetWarehouseID("mustang");
  const mustangPageNumber = useSelector((state) => state.mustang.currentPage);
  const mmax_warehouse_id = GetWarehouseID("mmax");
  const mmaxPageNumber = useSelector((state) => state.mmax.currentPage);
  const rdh_warehouse_id = GetWarehouseID("rdh");
  const rdhPageNumber = useSelector((state) => state.rdh.currentPage);
  const newOrdersCount = TabNewOrderCount(currentTab);
  const jwtToken = useSelector((state) => state.auth.jwtToken);

  const TotalOrders = async () => {
    try {
      const ccvReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: currentTab === 'CCVZalando' ? 'zalandoccvmo' : listing, listing : listing },
      ]);
      if(listing === "order") {
        dispatch(setCcvTotalOrders(ccvReq.data.total_orders));
      } else {
        dispatch(setCcvTotalPrepOrders(ccvReq.data.total_orders));
      }
      const ccvSiteReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: "preparation", listing : "preparation" },
      ]);
      dispatch(setCcvPrepSiteOrders(ccvSiteReq.data.total_orders));
      const ccvZalanReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: 'zalandoccvmo', listing : "preparation" },
      ]);
      dispatch(setCcvTotalZalandoOrders(ccvZalanReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de CCV", "warning");
    }
    try {
      const riekerReq = await GetOrders("rieker", "total_orders.php", [
        { id_warehouse: rieker_warehouse_id, page: listing },
      ]);
      listing === "order"
        ? dispatch(setRiekerTotalOrders(riekerReq.data.total_orders))
        : dispatch(setRiekerTotalPrepOrders(riekerReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Rieker", "warning");
    }
    try {
      const mustangReq = await GetOrders("mustang", "total_orders.php", [
        { id_warehouse: mustang_warehouse_id, page: listing },
      ]);
      listing === "order"
        ? dispatch(setMustangTotalOrders(mustangReq.data.total_orders))
        : dispatch(setMustangTotalPrepOrders(mustangReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Mustang", "warning");
    }
    try {
      const mmaxReq = await GetOrders("mmax", "total_orders.php", [
        { id_warehouse: mmax_warehouse_id, page: listing },
      ]);
      listing === "order"
        ? dispatch(setMmaxTotalOrders(mmaxReq.data.total_orders))
        : dispatch(setMmaxTotalPrepOrders(mmaxReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de MMax", "warning");
    }
    try {
      const rdhReq = await GetOrders("rdh", "total_orders.php", [
        { id_warehouse: rdh_warehouse_id, page: listing },
      ]);
      listing === "order"
        ? dispatch(setRdhTotalOrders(rdhReq.data.total_orders))
        : dispatch(setRdhTotalPrepOrders(rdhReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Rdh", "warning");
    }
  };

  const LiveTotalOrders = async () => {
    try {
      const ccvReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: currentTab === 'CCVZalando' ? 'zalandoccvmo' : "preparation", listing : "preparation" },
      ]);
      dispatch(setCcvTotalPrepOrders(ccvReq.data.total_orders));
      const ccvSiteReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: "preparation", listing : "preparation" },
      ]);
      dispatch(setCcvPrepSiteOrders(ccvSiteReq.data.total_orders));
      const ccvZalanReq = await GetOrders("ccv", "total_orders.php", [
        { id_warehouse: ccv_warehouse_id, page: 'zalandoccvmo', listing : "preparation" },
      ]);
      dispatch(setCcvTotalZalandoOrders(ccvZalanReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de CCV live", "warning")
    }
    try {
      const riekerReq = await GetOrders("rieker", "total_orders.php", [
        { id_warehouse: rieker_warehouse_id },
      ]);
      dispatch(setRiekerTotalPrepOrders(riekerReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Rieker live", "warning")
    }
    try {
      const mustangReq = await GetOrders("mustang", "total_orders.php", [
        { id_warehouse: mustang_warehouse_id },
      ]);
      dispatch(setMustangTotalPrepOrders(mustangReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Mustang live", "warning")
    }
    try {
      const mmaxReq = await GetOrders("mmax", "total_orders.php", [
        { id_warehouse: mmax_warehouse_id },
      ]);
      dispatch(setMmaxTotalPrepOrders(mmaxReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de MMax live", "warning")
    }
    try {
      const rdhReq = await GetOrders("rdh", "total_orders.php", [
        { id_warehouse: rdh_warehouse_id },
      ]);
      dispatch(setRdhTotalPrepOrders(rdhReq.data.total_orders));
    } catch (err) {
      showToast("Impossible d'obtenir le total des commandes de Rdh live", "warning")
    }
  };

  const FetchOrders = async (fetch_method) => {
    if (fetch_method === "page_load") {
      toggleLoader(true);
    }
    let response;
    if (currentTab === "CCV") {
      try {
        response = await GetOrders("ccv", "fetch_orders.php", [
          {
            tab: listing,
            id_warehouse: ccv_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: ccvPageNumber,
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (currentTab === "CCVZalando") {
      try {
        response = await GetOrders("ccv", "fetch_orders.php", [
          {
            tab: 'zalandoccvmo',
            id_warehouse: ccv_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: ccvPageNumber,
            listing: listing
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (currentTab === "Rieker") {
      try {
        response = await GetOrders("rieker", "fetch_orders.php", [
          {
            tab: listing,
            id_warehouse: rieker_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: riekerPageNumber,
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (currentTab === "Mustang") {
      try {
        response = await GetOrders("mustang", "fetch_orders.php", [
          {
            tab: listing,
            id_warehouse: mustang_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: mustangPageNumber,
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (currentTab === "MMax") {
      try {
        response = await GetOrders("mmax", "fetch_orders.php", [
          {
            tab: listing,
            id_warehouse: mmax_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: mmaxPageNumber,
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (currentTab === "Rdh") {
      try {
        response = await GetOrders("rdh", "fetch_orders.php", [
          {
            tab: listing,
            id_warehouse: rdh_warehouse_id,
            limit: pageSize,
            sort: sortName,
            sortBy: sortDirection,
            pageNumber: rdhPageNumber,
          },
        ]);
      } catch (err) {
        showToast("Impossible de récupérer de nouvelles commandes", "warning");
      }
    }
    if (fetch_method === "page_load") {
      toggleLoader(false);
    }
    if (response !== undefined && response.data !== undefined) {
      setOrders(response.data.orders);
      setTempOdSelected([]);
    }
  };
  useEffect(() => {
    const CheckAuthorizedUser = async () => {
      let isAuthorizedUser = await AuthChecker(jwtToken);
      if (!isAuthorizedUser) {
        dispatch(resetGeneralData());
        persistor.purge();
      }
      setAuthorizedUser(isAuthorizedUser);
    };
    FetchOrders("page_load");
    TotalOrders();
    CheckAuthorizedUser();
    const interval = setInterval(() => FetchOrders("page_sync"), 30000);
    const intervalTotal = setInterval(() => LiveTotalOrders(), 30000);
    return () => {
      clearInterval(interval);
      clearInterval(intervalTotal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    listing,
    pageSize,
    sortName,
    sortDirection,
    ccv_warehouse_id,
    dispatch,
    jwtToken,
    mmax_warehouse_id,
    mustang_warehouse_id,
    rieker_warehouse_id,
    ccvPageNumber,
    riekerPageNumber,
    mustangPageNumber,
    mmaxPageNumber,
    sChangeCount
  ]);

  return (
    <div className="col-md-10 right-panel">
      {!isAuthorizedUser && <LoginPopUp />}
      <div className="dashboard-right-main">
        {currentTab === 'CCVZalando' ? (
          <>
            <Tabs
              justify
              className="mb-3 col-md-5"
              onSelect={(key) => handleListing(key)}
              defaultActiveKey={listing}
            >
              <Tab
                eventKey="preparation"
                title={
                  newOrdersCount > 0 ? `À traiter (${newOrdersCount})` : "À traiter"
                }
              >
                <SearchForm />
                {orders && orders.length !== 0 && (
                  <OrdersList
                    orders={orders}
                    currentTab="CCVZalando"
                    pageSize={pageSize}
                    handlePageSize={handlePageSize}
                    handleCCVSorting={handleCCVSorting}
                    FetchOrders={FetchOrders}
                  />
                )}
                {!orders && <NoOrdersList />}
                <ActionButtons
                  currentTab="CCVZalando"
                  FetchOrders={FetchOrders}
                  TotalOrders={TotalOrders}
                />
              </Tab>
              <Tab eventKey="order" title="Toutes les commandes expédiées">
                <SearchForm />
                {orders && orders.length !== 0 && (
                  <OrdersList
                    orders={orders}
                    currentTab="CCVZalando"
                    pageSize={pageSize}
                    handlePageSize={handlePageSize}
                    handleCCVSorting={handleCCVSorting}
                    FetchOrders={FetchOrders}
                  />
                )}
                {!orders && <NoOrdersList />}
                <ActionButtons
                  currentTab="CCVZalando"
                  FetchOrders={FetchOrders}
                  TotalOrders={TotalOrders}
                />
              </Tab>
            </Tabs>

          </>
        ) : (
          <Tabs
            justify
            className="mb-3 col-md-5"
            onSelect={(key) => handleListing(key)}
            defaultActiveKey={listing}
          >
            <Tab
              eventKey="preparation"
              title={
                newOrdersCount > 0 ? `À traiter (${newOrdersCount})` : "À traiter"
              }
            >
              <SearchForm />
              {orders && orders.length !== 0 && (
                <OrdersList
                  orders={orders}
                  currentTab={currentTab}
                  pageSize={pageSize}
                  handlePageSize={handlePageSize}
                  handleCCVSorting={handleCCVSorting}
                  FetchOrders={FetchOrders}
                />
              )}
              {!orders && <NoOrdersList />}
              <ActionButtons
                currentTab={currentTab}
                FetchOrders={FetchOrders}
                TotalOrders={TotalOrders}
              />
            </Tab>
            <Tab eventKey="order" title="Toutes les commandes expédiées">
              <SearchForm />
              {orders && orders.length !== 0 && (
                <OrdersList
                  orders={orders}
                  currentTab={currentTab}
                  pageSize={pageSize}
                  handlePageSize={handlePageSize}
                  pageNumber={pageNumber}
                  handlePageNumber={handlePageNumber}
                  handleCCVSorting={handleCCVSorting}
                  FetchOrders={FetchOrders}
                />
              )}
              {!orders && <NoOrdersList />}
              <ActionButtons
                currentTab={currentTab}
                FetchOrders={FetchOrders}
                TotalOrders={TotalOrders}
              />
            </Tab>
          </Tabs>
        )}

      </div>
    </div>
  );
};
export default RightContent;
