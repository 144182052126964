import React, { useState } from "react";
import { Container, Col, Form, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import SearchPageSize from "./SearchPageSize";

const SearchPagination = ({SearchOrders, totalSearchOrders, pageSearchSize, handlePageSearchSize, pageSearchNumber, handlePageSearchNumber}) => {

  const currentPage = pageSearchNumber;
  const [tempPageNo, setTempPageNo] = useState(pageSearchNumber);
  const checkCount = totalSearchOrders;
  const totalPages = Math.ceil(checkCount / pageSearchSize);
  const pageStart = currentPage === 1 ? 1 : (currentPage - 1) * pageSearchSize + 1;
  const pageEnd =
    parseInt(currentPage) === parseInt(totalPages)
      ? checkCount
      : currentPage * pageSearchSize;

  const handleDirectPage = (e) => {
    const goToPage = parseInt(e.target.innerText);
    goToPage === 1 ? setTempPageNo(1) : setTempPageNo(goToPage);
    handlePageSearchNumber(tempPageNo);
  }

  const handlePrevClick = () => {
    const updatedPageNo = parseInt(tempPageNo) - 1;
    setTempPageNo(updatedPageNo);
    if (updatedPageNo <= 0 || !updatedPageNo) {
      alert("Not a valid page number");
      setTempPageNo(tempPageNo);
      return;
    }
    handlePageSearchNumber(updatedPageNo);
    //SearchOrders("page_load", updatedPageNo);
  }

  const handlePageNo = (e) => {
    setTempPageNo(e.target.value);
  }

  const handleGotoPage = (e) => {
    if (e.keyCode === 13) {
        if (tempPageNo > totalPages || !tempPageNo) {
          alert("Not a valid page number");
          setTempPageNo(1);
          return;
        }
        handlePageSearchNumber(tempPageNo);
        //SearchOrders("page_load", tempPageNo);
    }
  }

  const handleNextClick = () => {
    const updatedPageNo = parseInt(tempPageNo) + 1;
    setTempPageNo(updatedPageNo);
    if (updatedPageNo > totalPages || !updatedPageNo) {
      alert("Not a valid page number");
      setTempPageNo(tempPageNo);
      return;
    }
    handlePageSearchNumber(updatedPageNo);
    //SearchOrders("page_load", tempPageNo);
  }
  
  return (
    <Container
      fluid
      className="pagination-section d-flex justify-content-center mb-5"
    >
      <Row className="pagination-section-row">
        <Col md={12}>
          <Row className="justify-content-center d-flex align-items-center">
            <Pagination>
              <Pagination.Item
                disabled={currentPage === 1}
                value={1}
                onClick={(e) => handleDirectPage(e)}
              >
                1
              </Pagination.Item>
              <Pagination.Prev
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              />
              <li className="page-item">
                <Form.Control
                  type="number"
                  placeholder={currentPage}
                  onChange={handlePageNo}
                  value={tempPageNo}
                  onKeyUp={(e) => handleGotoPage(e)}
                />
              </li>
              <Pagination.Next
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
              />
              <Pagination.Item
                disabled={currentPage === totalPages}
                value={totalPages}
                onClick={(e) => handleDirectPage(e)}
              >
                {totalPages}
              </Pagination.Item>
            </Pagination>
            <div className="mx-3">
              <label className="col-form-label">
                Affichage {pageStart}-{pageEnd} sur {checkCount} (page{" "}
                {currentPage} / {totalPages})
              </label>
            </div>
            <div className="mx-3">
              <SearchPageSize
                pageSizeOptions={[35, 50, 100]}
                pageSize={pageSearchSize}
                handlePageSize={handlePageSearchSize}
                totalCount={checkCount}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default SearchPagination;
