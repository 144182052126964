import mmaxSlice, {
    setMmaxWarehouse,
    setMmaxPagination,
    setMmaxCurrentPage,
    setMmaxCurrentTab,
    setMmaxTotalOrders,
    setMmaxTotalPrepOrders,
    setMmaxNewOrders,
    selectMmaxWarehouse,
    selectMmaxPagination,
    selectMmaxCurrentPage,
    selectMmaxCurrentTab,
    setMmaxActiveTab,
    resetMmaxState
  } from "./mmaxSlice";
  
  export {
    setMmaxWarehouse,
    setMmaxPagination,
    setMmaxCurrentPage,
    setMmaxCurrentTab,
    setMmaxTotalOrders,
    setMmaxTotalPrepOrders,
    setMmaxNewOrders,
    selectMmaxWarehouse,
    selectMmaxPagination,
    selectMmaxCurrentPage,
    selectMmaxCurrentTab,
    setMmaxActiveTab,
    resetMmaxState
  };
  
  export default mmaxSlice;
  