import { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOdSelectedRows,
  setOdAmount,
  setOdCreditSlip,
  setOdRestock,
  setOdSelectedRows,
  setOdVoucher,
  setOdqty,
  setPartialRefundType,
  setSChangeCount,
  setShippingAmount,
  setSearchSChangeCount,
} from "../../store/generaldata/generalDataSlice";
import { StoreChange } from "../apiHelper";
import { CurrentTab, CurrentUserId } from "../generalHelper";
import { showToast } from "../toastHelper";
import { toast } from "react-toastify";
import { SearchContext } from "./searchContext";

export const OdContext = createContext();
export const OdProvider = ({ children }) => {
  const dispatch = useDispatch();
  const odSelectedRows = useSelector(
    (state) => state.generalData.odSelectedRows
  );
  const currentTab = CurrentTab();
  const currentUserId = CurrentUserId();
  const odOrderId = useSelector((state) => state.generalData.odOrderId);
  const odApiData = useSelector((state) => state.generalData.odApiData);
  const { toggleLoader, isSearchActive } = useContext(SearchContext);
  const [tempOdSelected, setTempOdSelected] = useState([]);

  const handleBulkOrderDetail = (
    e,
    idOrderDetail,
    idOrder,
    odUnitPrice,
    odQty,
    odShipping
  ) => {
    dispatch(
      setOdSelectedRows({
        odId: idOrderDetail,
        orderId: idOrder,
        odUnitPrice: odUnitPrice,
        odQty: odQty,
        odShipping: odShipping,
      })
    );
    document.getElementById(`qty_${idOrderDetail}`).value = parseInt(odQty);
    if (e.target.checked) {
      dispatch(
        setOdqty({
          quantity: document.getElementById(`qty_${idOrderDetail}`).value,
          odId: idOrderDetail,
        })
      );
    }
    /* document.getElementById(`price_${idOrderDetail}`).value = parseFloat(
      odUnitPrice * odQty
    ).toFixed(2); */
  };

  const handleMissingItem = (e, orderDetail) => {
    dispatch(
      setPartialRefundType({
        rfType: e.target.value,
        odId: orderDetail.id_order_detail,
      })
    );
  };

  const handelOdPartialRefund = () => {
    
    const issuePartialRefund = async () => {
      let tab = currentTab;
      if(currentTab === 'CCVZalando') {
        tab = 'CCV';
      }
      if (odSelectedRows.length > 0) {
        try {
          toggleLoader(true);
          setTempOdSelected(odSelectedRows);
          /* const response = await IssueRefundPartial(
            currentTab,
            "issue_partial_refund.php",
            {
              id_order_detail: odSelectedRows.toString(),
              od_partial_refund: odApiData,
              orderId: odOrderId,
              id_employee: currentUserId,
            }
          ); */
          const response = await StoreChange(
            tab,
            "store_change.php",
            {
              id_detail_products: odSelectedRows.toString(),
              od_store_change: odApiData,
              orderId: odOrderId,
              id_employee: currentUserId,
              action: 'changeAvailableWarehouse'
            }
          );
          if (response.data.result === 'success') {
            setTempOdSelected([]);
            throw new Error("Erreur de changement de magasin");
          } else {
            dispatch(resetOdSelectedRows());
            toggleLoader(false);
            toast.dismiss();
            showToast("Le magasin a été modifié avec succès", "success");
            isSearchActive ? dispatch(setSearchSChangeCount()) : dispatch(setSChangeCount());
          }
        } catch (err) {
          setTempOdSelected([]);
          toggleLoader(false);
          toast.dismiss();
          showToast("Impossible de changer de magasin", "error");
        }
      } else {
        toast.dismiss();
        showToast(
          "Veuillez sélectionner au moins un détail de la commande",
          "error"
        );
      }
    };
    let i = 0;
    while (i < odSelectedRows.length) {
      if (
        odApiData[odSelectedRows[i]]["quantity"] <= 0 ||
        !odApiData[odSelectedRows[i]]["quantity"]
      ) {
        toast.dismiss();
        showToast("Veuillez indiquer une quantité valide", "warning");
        return;
      }
      let qty = document.getElementById(`qty_${odSelectedRows[i]}`).value;
      if (
        qty <= 0 ||
        !qty
      ) {
        toast.dismiss();
        showToast("Veuillez indiquer une quantité valide", "warning");
        return;
      }
      /* if (
        odApiData[odSelectedRows[i]]["amount"] <= 0 ||
        !odApiData[odSelectedRows[i]]["amount"]
      ) {
        toast.dismiss();
        showToast("Veuillez indiquer un montant valide", "warning");
        return;
      } */
      i++;
    }
    issuePartialRefund();
  };

  const handleOdRestock = () => {
    dispatch(setOdRestock());
  };
  const handleOdCreditslip = () => {
    dispatch(setOdCreditSlip());
  };
  const handleOdVoucher = () => {
    dispatch(setOdVoucher());
  };

  const handleShippingCost = (e, order) => {
    let shipping_cost = e.target.value;
    if (parseFloat(shipping_cost) > parseFloat(order.shipping_cost)) {
      e.target.value = order.shipping_cost;
      shipping_cost = order.shipping_cost;
    }
    dispatch(setShippingAmount(shipping_cost));
  };

  const handleOdQty = (e, orderDetail) => {
    let currQty = e.target.value.replace(/\D/g, '');
    if (currQty > orderDetail.product_quantity) {
      e.target.value = currQty = orderDetail.product_quantity;
    } 
     
    /* let price = document.getElementById(`price_${orderDetail.id_order_detail}`);
    price =
      !price.value || price.value === 0
        ? parseFloat(currQty * orderDetail.unit_price_tax_incl).toFixed(2)
        : parseFloat(currQty * price.value).toFixed(2);
    price <= 0
      ? (document.getElementById(`price_${orderDetail.id_order_detail}`).value =
          orderDetail.unit_price_tax_incl)
      : (document.getElementById(`price_${orderDetail.id_order_detail}`).value =
          price);
    price > (orderDetail.unit_price_tax_incl * currQty).toFixed(2)
      ? (document.getElementById(`price_${orderDetail.id_order_detail}`).value =
          (orderDetail.unit_price_tax_incl * currQty).toFixed(2))
      : (document.getElementById(`price_${orderDetail.id_order_detail}`).value =
          price); */
    dispatch(
      setOdqty({
        quantity: currQty,
        odId: orderDetail.id_order_detail,
      })
    );
  };

  const handleOdAmount = (e, orderDetail) => {
    let currAmt = parseFloat(e.target.value).toFixed(2);
    if (
      currAmt >
      orderDetail.unit_price_tax_incl * orderDetail.product_quantity
    ) {
      e.target.value = currAmt = parseFloat(orderDetail.unit_price_tax_incl).toFixed(2);
    }
    dispatch(
      setOdAmount({
        amount: currAmt,
        odId: orderDetail.id_order_detail,
      })
    );
  };

  return (
    <OdContext.Provider
      value={{
        handleBulkOrderDetail,
        handleMissingItem,
        handelOdPartialRefund,
        handleOdQty,
        handleOdAmount,
        handleOdRestock,
        handleOdCreditslip,
        handleOdVoucher,
        handleShippingCost,
        tempOdSelected,
        setTempOdSelected,
      }}
    >
      {children}
    </OdContext.Provider>
  );
};
