const { createSlice } = require("@reduxjs/toolkit");

export const rdhSlice = createSlice({
  name: "rdh",
  initialState: {
    idWarehouse: "",
    pagination: 35,
    currentPage: 1,
    currentTab: "preparation",
    totalOrders: 0,
    totalPrepOrders: 0,
    newOrders: 0,
    newPrepOrders: 0,
    activeTab: 0,
  },
  reducers: {
    setRdhWarehouse: (state, action) => {
      state.idWarehouse = action.payload;
      localStorage.setItem("rdhwarehouse", state.idWarehouse);
    },
    setRdhPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setRdhCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRdhCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setRdhTotalOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newOrders = 0;
      }
      if (state.totalOrders > 0) {
        let prevTotalOrders = state.totalOrders;
        state.totalOrders = action.payload;
        state.newOrders = Math.max(
          state.newOrders + (state.totalOrders - prevTotalOrders),
          0
        );
      } else {
        state.totalOrders = action.payload;
      }
    },
    setRdhTotalPrepOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newPrepOrders = 0;
      }
      if (state.totalPrepOrders > 0) {
        let prevTotalPrepOrders = state.totalPrepOrders;
        state.totalPrepOrders = action.payload;
        state.newPrepOrders = Math.max(
          state.newPrepOrders + (state.totalPrepOrders - prevTotalPrepOrders),
          0
        );
      } else {
        state.totalPrepOrders = action.payload;
      }
    },
    setRdhNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    setRdhActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetRdhState: (state, action) => {
      state.idWarehouse = "";
      state.pagination = 35;
      state.currentPage = 1;
      state.currentTab = "preparation";
      state.totalOrders = 0;
      state.totalPrepOrders = 0;
      state.newOrders = 0;
      state.newPrepOrders = 0;
      state.activeTab = 1;
    },
  },
});

export const {
  setRdhWarehouse,
  setRdhPagination,
  setRdhCurrentPage,
  setRdhCurrentTab,
  setRdhTotalOrders,
  setRdhTotalPrepOrders,
  setRdhNewOrders,
  setRdhActiveTab,
  resetRdhState,
} = rdhSlice.actions;
export const selectRdhWarehouse = (state) => state.idWarehouse;
export const selectRdhPagination = (state) => state.pagination;
export const selectRdhCurrentPage = (state) => state.currentPage;
export const selectRdhCurrentTab = (state) => state.currentTab;
export default rdhSlice.reducer;
