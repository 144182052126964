import React from "react";
import "../assets/css/custom.css";

const PageOverlayLoader = () => {
  return (
    <div id="overlay">
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};
export default PageOverlayLoader;
