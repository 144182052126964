import React, { createContext, useEffect, useState } from "react";
import { CurrentTab } from "../generalHelper";
import { GetOrders } from "../apiHelper";
import GetWarehouseID from "../warehouseHelper";
import { useSelector } from "react-redux";

export const SearchContext = createContext();
export const SearchProvider = ({ children }) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchOrders, setSearchOrders] = useState([]);
  const [sortName, setSearchSortName] = useState("");
  const [sortDirection, setSearchSortDirection] = useState("");
  const currentTab = CurrentTab();
  let listing = useSelector((state) => state.generalData.listingView);
  const [searchLoader, setSearchLoader] = useState(false);
  const ccv_warehouse_id = GetWarehouseID("ccv");
  const ccvPageNumber = useSelector((state) => state.ccv.currentPage);
  const rieker_warehouse_id = GetWarehouseID("rieker");
  const riekerPageNumber = useSelector((state) => state.rieker.currentPage);
  const mustang_warehouse_id = GetWarehouseID("mustang");
  const mustangPageNumber = useSelector((state) => state.mustang.currentPage);
  const mmax_warehouse_id = GetWarehouseID("mmax");
  const rdh_warehouse_id = GetWarehouseID("rdh");
  const mmaxPageNumber = useSelector((state) => state.mmax.currentPage);
  const rdhPageNumber = useSelector((state) => state.rdh.currentPage);
  const searchsChangeCount = useSelector((state) => state.generalData.searchsChangeCount);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateSearch, setDateSearch] = useState(false);

  const [selectedRange, setSelectedRange] = useState([null, null]);

  const [totalSearchOrders, setTotalSearchOrders] = useState(0);
  const [pageSearchSize, setPageSearchSize] = useState(35);
  const [pageSearchNumber, setPageSearchNumber] = useState(1);

  const handlePageSearchSize = (selectedPageSize) => {
    setPageSearchSize(selectedPageSize);
    setPageSearchNumber(1);
  }

  const handlePageSearchNumber = (selectedPage) => {
    setPageSearchNumber(selectedPage);
  }

  const handleOrderSearch = () => {
    setSearchActive(true);
    SearchOrders("page_load");
  };
  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      setSearchActive(true);
      SearchOrders("page_load");
    } else {
      setSearchText(e.target.value);
    }
    if (!searchText && !dateSearch) {
      setSearchActive(false);
    }
  };
  const handleReset = () => {
    setSearchActive(false);
    setSearchText("");
    setSelectedRange([null, null]);
    setDateSearch(false);
    setStartDate(new Date());
    setEndDate(new Date());
    setTotalSearchOrders(0);
    setPageSearchSize(35);
    setPageSearchNumber(1);
    const elements = document.querySelectorAll(".rs-picker-toggle-clean");
    elements.forEach(element => {
      element.click();
    });
  };

  const toggleLoader = (toggle) => {
    setSearchLoader(toggle);
  };

  const SearchOrders = async (fetch_method) => {
    if (fetch_method === "page_load") {
      toggleLoader(true);
    }
    let searchType = "info";
    let orgListing = listing;
    if (typeof searchText === "string") {
      if (!isNaN(searchText)) {
        searchType = "order";
      }
    }
    if(currentTab === 'CCVZalando') {
      listing = 'zalandoccvmo';
    }
    let searchData = {
      tab: listing,
      listing: orgListing,
      id_warehouse: currentTab === "CCV" ? ccv_warehouse_id : currentTab === "Rieker" ? rieker_warehouse_id : currentTab === "Mustang" ? mustang_warehouse_id : currentTab === "MMax" ? mmax_warehouse_id : currentTab === "Rdh" ? rdh_warehouse_id : "",
      limit: pageSearchSize,
      sort: sortName,
      sortBy: sortDirection,
      pageNumber: pageSearchNumber,
      search_string: searchText,
      search_type: searchType,
      dateSearch: dateSearch,
      startDate: startDate,
      endDate: endDate,
    };
    let response = await GetOrders(currentTab.toLowerCase(), "search_orders.php", [searchData,]);
    if (fetch_method === "page_load") {
      toggleLoader(false);
    }
    if (!response.data.orders || response.data.orders == null) {
      setSearchOrders([]);
      setTotalSearchOrders(0);
    } else {
      setSearchOrders(response.data.orders);
      setTotalSearchOrders(response.data.total_orders);
    }
  };

  useEffect(() => {
    SearchOrders("page_load");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSearchSize, pageSearchNumber, sortName, sortDirection, searchsChangeCount]);

  const SearchDateOrders = async (fetch_method, startDate, endDate, dateSearch) => {
    if (fetch_method === "page_load") {
      toggleLoader(true);
    }
    let searchType = "info";
    if (typeof searchText === "string") {
      if (!isNaN(searchText)) {
        searchType = "order";
      }
    }
    let searchData = {
      tab: listing,
      id_warehouse: currentTab === "CCV" ? ccv_warehouse_id : currentTab === "Rieker" ? rieker_warehouse_id : currentTab === "Mustang" ? mustang_warehouse_id : currentTab === "MMax" ? mmax_warehouse_id : currentTab === "Rdh" ? rdh_warehouse_id : "",
      limit: pageSearchSize,
      sort: sortName,
      sortBy: sortDirection,
      pageNumber: currentTab === "CCV" ? ccvPageNumber : currentTab === "Rieker" ? riekerPageNumber : currentTab === "Mustang" ? mustangPageNumber : currentTab === "MMax" ? mmaxPageNumber : currentTab === "Rdh" ? rdhPageNumber : "",
      search_string: searchText,
      search_type: searchType,
      dateSearch: dateSearch,
      startDate: startDate,
      endDate: endDate,
    };
    let response = await GetOrders(currentTab.toLowerCase(), "search_orders.php", [searchData,]);
    if (fetch_method === "page_load") {
      toggleLoader(false);
    }
    if (!response.data.orders || response.data.orders == null) {
      setSearchOrders([]);
      setTotalSearchOrders(0);
    } else {
      setSearchOrders(response.data.orders);
      setTotalSearchOrders(response.data.total_orders);
    }
  };

  const handleSelectedDate = (value) => {
    if(value) {
      setSelectedRange(value);
      const startYear = value[0].getFullYear();
      const startMonth = String(value[0].getMonth() + 1).padStart(2, '0');
      const startDay = String(value[0].getDate()).padStart(2, '0');
      const startDate = `${startYear}-${startMonth}-${startDay} 00:00:00`;
      setStartDate(`${startYear}-${startMonth}-${startDay} 00:00:00`);
      const endYear = value[1].getFullYear();
      const endMonth = String(value[1].getMonth() + 1).padStart(2, '0');
      const endDay = String(value[1].getDate()).padStart(2, '0');
      setEndDate(`${endYear}-${endMonth}-${endDay} 23:59:59`);
      const endDate = `${endYear}-${endMonth}-${endDay} 23:59:59`;
      setDateSearch(true);
      const dateSearch = true;
      SearchDateOrders("page_load", startDate, endDate, dateSearch);
      setSearchActive(true);
    } else {
      setSelectedRange([null, null]);
      setDateSearch(false);
      setSearchActive(false);
      setStartDate(new Date());
      setEndDate(new Date());
      setSearchText("");
      setTotalSearchOrders(0);
      setPageSearchSize(10);
      setPageSearchNumber(1);
    }
  }

  const handleDateSearch = () => {
    if(dateSearch) {
      SearchOrders("page_load");
      setSearchActive(true);
    }
  }

  const handleDateApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') +
      ' - ' +
      picker.endDate.format('DD/MM/YYYY')
    );
  }

  const handleDateReset = () => {
    setSelectedRange([null, null]);
    setDateSearch(false);
  }

  return (
    <SearchContext.Provider
      value={{
        isSearchActive,
        setSearchActive,
        searchText,
        setSearchText,
        handleOrderSearch,
        handleSearch,
        handleReset,
        searchOrders,
        searchLoader,
        toggleLoader,
        handleSelectedDate,
        handleDateSearch,
        handleDateApply,
        handleDateReset,
        selectedRange,
        setDateSearch,
        setSelectedRange,
        SearchOrders,
        totalSearchOrders,
        pageSearchSize,
        handlePageSearchSize,
        pageSearchNumber,
        handlePageSearchNumber,
        setSearchSortName,
        setSearchSortDirection
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
