import React from "react";
import { Form } from "react-bootstrap";

const SearchPageSize = ({
  pageSizeOptions,
  pageSize,
  handlePageSize,
  totalCount,
}) => {
  const handlePageSelector = (e) => {
    let selectedPage = e.target.value;
    handlePageSize(selectedPage);
  }
  return (
    <div className="form-group row mb-0 d-flex align-items-center">
      <Form.Label className="mb-0">Éléments par page :</Form.Label>
      <Form.Group controlId="pageSize">
        <Form.Select onChange={handlePageSelector} value={pageSize}>
          {pageSizeOptions.map((size) => {
            if(parseInt(size) === 35) {
              return (
                <option key={size} value={size}>
                  {size}
                </option>
              );
            }
            if (parseInt(size) < parseInt(totalCount) && parseInt(size) > 35) {
              return (
                <option key={size} value={size}>
                  {size}
                </option>
              );
            }
            return 1;
          })}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default SearchPageSize;
