import axios from "axios";
import qs from "qs";

const MODE_DEV = process.env.REACT_APP_DEV_MODE;

let CCV_API_BASE_URL;
let RIEKER_API_BASE_URL;
let MUSTANG_API_BASE_URL;
let MMAX_API_BASE_URL;
let RDH_API_BASE_URL;

if (MODE_DEV === "1") {
  CCV_API_BASE_URL =
    "https://beta.store-and-supply.com/ccvmode.com/import-script/gpc/";
  RIEKER_API_BASE_URL =
    "https://beta.store-and-supply.com/riekeronline/import-script/gpc/";
  MUSTANG_API_BASE_URL =
    "https://beta.store-and-supply.com/mustang/import-script/gpc/";
  MMAX_API_BASE_URL =
    "https://beta.store-and-supply.com/monsieur-max.com/import-script/gpc/";
} else {
  CCV_API_BASE_URL = "https://www.ccvmode.com/import-script/gpc/";
  RIEKER_API_BASE_URL = "https://www.rieker-online.fr/import-script/gpc/";
  MUSTANG_API_BASE_URL = "https://www.mustang-online.fr/import-script/gpc/";
  MMAX_API_BASE_URL = "https://www.monsieur-max.com/import-script/gpc/";
  RDH_API_BASE_URL = "https://www.ruedeshommes.com/import-script/gpc/";
}

const SERVER_API =
  process.env.REACT_APP_DEV_MODE === "1"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRODUCTION_API;

const ccvInstance = axios.create({
  baseURL: CCV_API_BASE_URL,
});

const riekerInstance = axios.create({
  baseURL: RIEKER_API_BASE_URL,
});

const mustangInstance = axios.create({
  baseURL: MUSTANG_API_BASE_URL,
});

const mmaxInstance = axios.create({
  baseURL: MMAX_API_BASE_URL,
});

const rdhInstance = axios.create({
  baseURL: RDH_API_BASE_URL,
});

const serverInstance = axios.create({
  baseURL: SERVER_API,
});

export const GetOrders = (site, path, params) => {
  let orders;
  const queryString = qs.stringify(params[0]);
  switch (site) {
    case "ccv":
    case "ccvzalando":
      orders = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      orders = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      orders = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      orders = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      orders = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return orders;
};

export const GetInvoice = (site, path, params) => {
  let invoice;
  const queryString = qs.stringify(params[0]);
  switch (site.toLowerCase()) {
    case "ccv":
    case "ccvzalando":
      invoice = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      invoice = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      invoice = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      invoice = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      invoice = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return invoice;
};

export const GetShippingLabel = (site, path, params) => {
  let label;
  const queryString = qs.stringify(params[0]);
  switch (site.toLowerCase()) {
    case "ccv":
    case "ccvzalando":
      label = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      label = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      label = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      label = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      label = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return label;
};

export const GetProductData = (site, path, params) => {
  let label;
  const queryString = qs.stringify(params[0]);
  switch (site.toLowerCase()) {
    case "ccv":
      label = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      label = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      label = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      label = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      label = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return label;
};

export const ChangePrepStatus = (site, path, params) => {
  let response;
  const queryString = qs.stringify(params[0]);
  switch (site.toLowerCase()) {
    case "ccv":
    case "ccvzalando":
      response = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      response = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      response = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      response = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      response = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return response;
};

export const ChangeShipStatus = (site, path, params) => {
  let response;
  const queryString = qs.stringify(params[0]);
  switch (site.toLowerCase()) {
    case "ccv":
    case "ccvzalando":
      response = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      response = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      response = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      response = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      response = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return response;
};

export const checkLogin = (options) => {
  return serverInstance.request(options);
};

export const IssueRefundPartial = (site, path, params) => {
  let response;
  const queryString = qs.stringify(params);
  switch (site.toLowerCase()) {
    case "ccv":
      response = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      response = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      response = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      response = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      response = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return response;
};

export const StoreChange = (site, path, params) => {
  let response;
  const queryString = qs.stringify(params);
  switch (site.toLowerCase()) {
    case "ccv":
      response = ccvInstance.get(path + "?" + queryString);
      break;
    case "rieker":
      response = riekerInstance.get(path + "?" + queryString);
      break;
    case "mustang":
      response = mustangInstance.get(path + "?" + queryString);
      break;
    case "mmax":
      response = mmaxInstance.get(path + "?" + queryString);
      break;
    case "rdh":
      response = rdhInstance.get(path + "?" + queryString);
      break;
    default:
  }
  return response;
};