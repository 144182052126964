import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    const user = localStorage.getItem("isLoggedIn");
    return user;
}

const PrivateRoute = () => {
    let authUser = useAuth();
    return authUser ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;