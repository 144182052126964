import React, { useState, useContext, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Badge,
  Image,
  ButtonToolbar,
  Button,
  Form,
  Figure,
  Spinner,
} from "react-bootstrap";
import PaginationBottom from "./PaginationBottom";
import { GetInvoice, GetShippingLabel } from "../helper/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOrderErrorRows,
  resetOrderSelectedRows,
  setOrderSelectedRows,
} from "../store/generaldata/generalDataSlice";
import { SearchContext } from "../helper/context/searchContext";
import { OdContext } from "../helper/context/odContext";
import amazon from "../assets/img/amazon.png";
import eshop from "../assets/img/e-shop.png";
import laredoute from "../assets/img/la-redoute.png";
import spartoo from "../assets/img/spartoo.png";
import clickcollect from "../assets/img/click&collect.png";
import zalando from "../assets/img/Zalando.png";
import cDisount from "../assets/img/CDiscount.png";
import tradeinn from "../assets/img/trade-inn.png";
import { showToast } from "../helper/toastHelper";
import { toast } from "react-toastify";
import { CurrentListing } from "../helper/generalHelper";
import SearchPagination from "./SearchPagination";
const OrdersList = ({
  orders,
  currentTab,
  pageSize,
  handlePageSize,
  pageNumber,
  handlePageNumber,
  handleCCVSorting,
  FetchOrders,
}) => {
  const selectedRows = useSelector(
    (state) => state.generalData.orderSelectedRows
  );
  const attendeStatuses = ['2','32','33','34','35','36','37','38','39','40','41','42','53','54','55','56','57','58','59','60','61','62','63','64','65','66','67','74','75','76','77','87', '113'];
  const totalOrders = useSelector(
    (state) => state.generalData.totalSelectedRows
  );
  const odSelectedRows = useSelector(
    (state) => state.generalData.odSelectedRows
  );
  const orderErrorRows = useSelector((state) => state.generalData.orderErrorRows);
  const odApiData = useSelector((state) => state.generalData.odApiData);
  const odOrderId = useSelector((state) => state.generalData.odOrderId);
  const dispatch = useDispatch();
  const {
    isSearchActive,
    searchOrders,
    toggleLoader,
    SearchOrders,
    totalSearchOrders,
    pageSearchSize,
    handlePageSearchSize,
    pageSearchNumber,
    handlePageSearchNumber,
  } = useContext(SearchContext);
  let listing = CurrentListing();
  if(currentTab === "CCVZalando") {
    // for hiding the store cahnge
    listing = 'order';
  }
  const {
    handleBulkOrderDetail,
    handleMissingItem,
    handelOdPartialRefund,
    handleOdQty,
    handleShippingCost,
  } = useContext(OdContext);
  const handleBulkOrder = (e, id_order) => {
    e.stopPropagation();
    dispatch(setOrderSelectedRows(id_order));
  };
  const handleBulkRowSelection = (e) => {
    dispatch(resetOrderSelectedRows());
    if (isSearchActive) {
      searchOrders.forEach((order) => {
        handleBulkOrder(e, order.id_order);
      });
    } else {
      orders.forEach((order) => {
        handleBulkOrder(e, order.id_order);
      });
    }
  };
  const handleBulkRemoveSelected = () => {
    dispatch(resetOrderSelectedRows());
    dispatch(resetOrderErrorRows());
  };
  const handleInvoice = (e, id_order) => {
    e.stopPropagation();
    const downloadInvoice = async () => {
      toggleLoader(true);
      try {
        const response = await GetInvoice(currentTab, "download_invoice.php", [
          { id_order: id_order },
        ]);
        toggleLoader(false);
        const fileURL = response.data;
        window.open(fileURL, "PRINT");
      } catch (err) {
        toggleLoader(false);
        toast.dismiss();
        showToast("Impossible de télécharger la facture BL", "error");
      }
    };
    downloadInvoice();
  };
  const handleShippingLabel = (e, id_order) => {
    e.stopPropagation();
    const downloadShippingLabel = async () => {
      toggleLoader(true);
      try {
        const response = await GetShippingLabel(
          currentTab,
          "download_shipping_label.php",
          [{ checkbox: id_order, type: "0" }]
        );
        toggleLoader(false);
        if(response.data.url) {
          console.log(response.data.url);
          const fileURL = response.data.url;
          window.open(fileURL, "PRINT");
        } else {
          toast.dismiss();
          showToast(
            "Impossible de télécharger l'étiquette d'expédition",
            "error"
          );
        }
      } catch (err) {
        toggleLoader(false);
        toast.dismiss();
        showToast(
          "Impossible de télécharger l'étiquette d'expédition",
          "error"
        );
      }
    };
    downloadShippingLabel();
  };

  const [showCancelButton, setShowCancelButton] = useState(false);

  const waitForPartialRefund = useRef();

  const handleRefundSubmit = () => {
    setShowCancelButton(true);
    waitForPartialRefund.current = setTimeout(() => {
      setShowCancelButton(false);
      handelOdPartialRefund();
      dispatch(resetOrderSelectedRows());
    }, 3000);
  };

  const handleCancel = () => {
    setShowCancelButton(false);
    clearTimeout(waitForPartialRefund.current);
  };

  return (
    <Container fluid className="p-0">
      <div className="row">
        <div className="col-md-12">
          <div className="selection-links">
            <Container fluid className="p-0">
              <Row className="mb-5 mt-0">
                <Col md={2} className="d-flex justify-content-start align-left">
                  <span onClick={(e) => handleBulkRowSelection(e)}>
                    Tout sélectionner
                  </span>
                </Col>
                <Col
                  md={2}
                  className="d-flex justify-content-start align-right"
                >
                  <span onClick={handleBulkRemoveSelected}>
                    Effacer la sélection
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
          {totalOrders > 0 && (
            <div className="orders-selected">
              <Container fluid className="p-0">
                <Row className="mb-5 mt-2">
                  <Col
                    md={5}
                    className="d-flex justify-content-start align-left"
                  >
                    <>
                      <Badge>{totalOrders}</Badge>
                      <span>commande sélectionnée</span>
                    </>
                  </Col>
                </Row>
              </Container>
            </div>
          )}

          {!isSearchActive ? (
            <div className="commande-main mb-5 mt-0">
              <div className="commande-main-header">
                <Container fluid className="p-0">
                  <Row className="show-grid">
                    <Col></Col>
                    <Col>
                      {"N° commande"}{" "}
                      <span
                        onClick={(e) => handleCCVSorting("order_id", "desc")}
                      >
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span
                        onClick={(e) => handleCCVSorting("order_id", "asc")}
                      >
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Client"}{" "}
                      <span onClick={(e) => handleCCVSorting("client", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("client", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>{"Transporteur"} </Col>
                    <Col>
                      {"Total"}{" "}
                      <span onClick={(e) => handleCCVSorting("total", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("total", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Paiement"}{" "}
                      <span
                        onClick={(e) => handleCCVSorting("payment", "desc")}
                      >
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("payment", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Attente"}{" "}
                      <span onClick={(e) => handleCCVSorting("time", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("time", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col className="temp-width"></Col>
                    <Col className="text-center">
                      {"Statut"}{" "}
                      <span onClick={(e) => handleCCVSorting("status", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("status", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col className="text-center">{"Date"} </Col>
                    <Col className="text-center">{"BL"}</Col>
                    <Col>{"Étiquette"}</Col>
                  </Row>
                </Container>
              </div>
              <div className="commande-main-list">
                <Container fluid className="p-0">
                  <Accordion defaultActiveKey="0">
                    {orders.map((order) => (
                      <Accordion.Item
                        eventKey={order.id_order}
                        key={order.id_order}
                      >
                        <Accordion.Header>
                          <Container fluid className="p-0">
                            <Row className="show-result">
                              <Col>
                                <input
                                  onClick={(e) =>
                                    handleBulkOrder(e, order.id_order)
                                  }
                                  className={`form-check-input ${orderErrorRows && orderErrorRows.includes(order.id_order) ? `cc-error` : null}`}
                                  type="checkbox"
                                  value={order.id_order}
                                  checked={selectedRows.includes(
                                    order.id_order
                                  )}
                                  onChange={(e) => {}}
                                />
                              </Col>
                              <Col>Commande {order.id_order}</Col>
                              <Col>{order.firstname}</Col>
                              <Col>{order.carrier_name}</Col>
                              <Col>{order.total_paid}</Col>
                              <Col>
                                {order.payment === "cdiscount" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={cDisount}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "tradeinn" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={tradeinn}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "spartoo" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={spartoo}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "laredoute" ||
                                order.payment === "laredoutemirakl" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={laredoute}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "amazon" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={amazon}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "zalando" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={zalando}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "zalandoccvmo" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={zalando}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.id_carrier === "116" &&
                                currentTab === "CCV" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={clickcollect}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.id_carrier !== "116" &&
                                order.payment !== "zalando" &&
                                order.payment !== "zalandoccvmo" &&
                                order.payment !== "amazon" &&
                                order.payment !== "laredoutemirakl" &&
                                order.payment !== "laredoute" &&
                                order.payment !== "spartoo" &&
                                order.payment !== "cdiscount" &&
                                order.payment !== "tradeinn" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={eshop}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col>
                                {attendeStatuses.includes(order.order_status) && (
                                  <span
                                    className={
                                      order.order_time <= 8
                                        ? `delivery-time green-bg`
                                        : order.order_time > 8 &&
                                          order.order_time <= 21
                                        ? `delivery-time orange-bg`
                                        : `delivery-time red-bg`
                                    }
                                  >
                                    {order.order_time === "0" ||
                                    order.order_time === "1"
                                      ? `<1h`
                                      : `${order.order_time}h`}
                                  </span>
                                )}
                              </Col>
                              <Col className="temp-width">
                                <i
                                  className={
                                    order.order_status === "3" ||
                                    order.order_status === "43"
                                      ? `green bi bi-check-circle`
                                      : ""
                                  }
                                ></i>
                              </Col>
                              <Col className="order-status-name">
                                <span className={order.order_status_color}>
                                  {order.order_status_name}
                                </span>
                              </Col>
                              <Col className="text-center">
                                {order.order_date}
                              </Col>
                              <Col className="text-center">
                                <span
                                  className="btn bg-transparent"
                                  onClick={(e) =>
                                    handleInvoice(e, order.id_order)
                                  }
                                >
                                  <i className="bi bi-file-earmark-text"></i>
                                </span>
                              </Col>
                              <Col>
                                <span
                                  className="btn bg-transparent"
                                  onClick={(e) =>
                                    handleShippingLabel(e, order.id_order)
                                  }
                                >
                                  <i className="bi bi-box-seam"></i>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Container fluid className="p-0 d-flex flex-wrap align-items-center justify-content-start">
                            <div className="order-product-details col-md-7">
                              {order.order_details.map((orderDetail, index) => (
                                <Row
                                  className="show-product-list justify-content-center mt-4 mb-4"
                                  key={orderDetail.id_order_detail}
                                >
                                  <Col md={1} className="offset-1">
                                    <input
                                      onClick={(e) =>
                                        handleBulkOrderDetail(
                                          e,
                                          orderDetail.id_order_detail,
                                          order.id_order,
                                          orderDetail.unit_price_tax_incl,
                                          orderDetail.product_quantity
                                        )
                                      }
                                      className="form-check-input od-checkbox"
                                      type="checkbox"
                                      value={orderDetail.id_order_detail}
                                      checked={odSelectedRows.includes(
                                        orderDetail.id_order_detail
                                      )}
                                      onChange={(e) => {}}
                                    />
                                  </Col>
                                  <Col md={2} className="p-0">
                                    <div className="product-img">
                                      <Image
                                        src={orderDetail.product_image}
                                        thumbnail
                                      />
                                    </div>
                                  </Col>
                                  <Col md={2} className="product-detail p-0">
                                    <div className="product-detail">
                                      <p>{orderDetail.product_name}</p>
                                      <p>
                                        <span>
                                          {orderDetail.product_brand} -{" "}
                                          {orderDetail.product_reference}
                                        </span>
                                      </p>
                                      <p>
                                        <span>
                                          (Taille : {orderDetail.product_size} -
                                          Couleurs : {orderDetail.product_color})
                                        </span>
                                      </p>
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    Quantité : {orderDetail.product_quantity}
                                  </Col>
                                  <Col md={1}>
                                    {orderDetail.total_price_tax_incl}
                                  </Col>
                                  <Col md={2} className="orderdetail-qty">
                                    <div className="input-group">
                                      <input
                                        className="form-control"
                                        disabled={
                                          odSelectedRows.length > 0 &&
                                          odSelectedRows.includes(
                                            orderDetail.id_order_detail
                                          )
                                            ? null
                                            : `disabled`
                                        }
                                        id={`qty_${orderDetail.id_order_detail}`}
                                        onChange={(e) =>
                                          handleOdQty(e, orderDetail)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        / {orderDetail.product_quantity}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                            {(listing === "preparation" || currentTab === 'CCVZalando') &&
                            odSelectedRows.length > 0 &&
                            odOrderId === order.id_order ? (
                              <Row className={`show-product-button signaler-button justify-content-start`}>
                                <Col md={12}>
                                  <ButtonToolbar className={`col-md-12 download-button position-relative ${showCancelButton ? 'd-none' : ''} `}>
                                    <Button onClick={handleRefundSubmit}>
                                      Signaler Indisponibilité
                                    </Button>
                                  </ButtonToolbar>
                                </Col>
                                <Col md={12}>
                                  <ButtonToolbar className={`col-md-12 cancel-button ${showCancelButton ? '' : 'd-none'}`}>
                                    <Button onClick={handleCancel}>
                                    <Spinner animation="border" className="position-relative custom-spinner"/>
                                    <div className="position-relative spinner-text">Annuler</div> 
                                    </Button>
                                  </ButtonToolbar>
                                </Col>
                              </Row>
                            ) : null}
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Container>
              </div>
            </div>
          ) : (
            <div className="commande-main mb-5 mt-0">
              <div className="commande-main-header">
                <Container fluid className="p-0">
                  <Row className="show-grid">
                  <Col></Col>
                    <Col>
                      {"N° commande"}{" "}
                      <span
                        onClick={(e) => handleCCVSorting("order_id", "desc")}
                      >
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span
                        onClick={(e) => handleCCVSorting("order_id", "asc")}
                      >
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Client"}{" "}
                      <span onClick={(e) => handleCCVSorting("client", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("client", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>{"Transporteur"} </Col>
                    <Col>
                      {"Total"}{" "}
                      <span onClick={(e) => handleCCVSorting("total", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("total", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Paiement"}{" "}
                      <span
                        onClick={(e) => handleCCVSorting("payment", "desc")}
                      >
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("payment", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col>
                      {"Attente"}{" "}
                      <span onClick={(e) => handleCCVSorting("time", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("time", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col className="temp-width"></Col>
                    <Col className="text-center">
                      {"Statut"}{" "}
                      <span onClick={(e) => handleCCVSorting("status", "desc")}>
                        <i className="bi bi-arrow-down"></i>
                      </span>{" "}
                      <span onClick={(e) => handleCCVSorting("status", "asc")}>
                        <i className="bi bi-arrow-up"></i>
                      </span>
                    </Col>
                    <Col className="text-center">{"Date"} </Col>
                    <Col className="text-center">{"BL"}</Col>
                    <Col>{"Étiquette"}</Col>
                  </Row>
                </Container>
              </div>
              {searchOrders.length === 0 ? (
                <div className="empty-results mt-3">
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="text-center grid-table-empty">
                          <p className="mb-0">
                            <i className="material-icons">warning</i>
                          </p>
                          <p className="mb-2">Aucun enregistrement trouvé</p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : null}
              <div className="commande-main-list">
                <Container fluid className="p-0">
                  <Accordion defaultActiveKey="0">
                    {searchOrders.map((order) => (
                      <Accordion.Item
                        eventKey={order.id_order}
                        key={order.id_order}
                      >
                        <Accordion.Header>
                          <Container fluid className="p-0">
                            <Row className="show-result">
                              <Col>
                                <input
                                  onClick={(e) =>
                                    handleBulkOrder(e, order.id_order)
                                  }
                                  className={`form-check-input ${orderErrorRows && orderErrorRows.includes(order.id_order) ? `cc-error` : null}`}
                                  type="checkbox"
                                  value={order.id_order}
                                  checked={selectedRows.includes(
                                    order.id_order
                                  )}
                                  onChange={(e) => {}}
                                />
                              </Col>
                              <Col>Commande {order.id_order}</Col>
                              <Col>{order.firstname}</Col>
                              <Col>{order.carrier_name}</Col>
                              <Col>{order.total_paid}</Col>
                              <Col>
                                {order.payment === "cdiscount" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={cDisount}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "tradeinn" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={tradeinn}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "spartoo" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={spartoo}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "laredoute" ||
                                order.payment === "laredoutemirakl" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={laredoute}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "amazon" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={amazon}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "zalando" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={zalando}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.payment === "zalandoccvmo" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={zalando}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.id_carrier === "116" &&
                                currentTab === "CCV" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={clickcollect}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                                {order.id_carrier !== "116" &&
                                order.payment !== "zalando" &&
                                order.payment !== "zalandoccvmo" &&
                                order.payment !== "amazon" &&
                                order.payment !== "laredoutemirakl" &&
                                order.payment !== "laredoute" &&
                                order.payment !== "spartoo" &&
                                order.payment !== "cdiscount" &&
                                order.payment !== "tradeinn" ? (
                                  <Figure>
                                    <Figure.Image
                                      width={65}
                                      height={39}
                                      alt="230x39"
                                      src={eshop}
                                    />
                                  </Figure>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col>
                                {attendeStatuses.includes(order.order_status) && (
                                  <span
                                    className={
                                      order.order_time <= 8
                                        ? `delivery-time green-bg`
                                        : order.order_time > 8 &&
                                          order.order_time <= 21
                                        ? `delivery-time orange-bg`
                                        : `delivery-time red-bg`
                                    }
                                  >
                                    {order.order_time === "0" ||
                                    order.order_time === "1"
                                      ? `< 1h`
                                      : `${order.order_time}h`}
                                  </span>
                                )}
                              </Col>
                              <Col className="temp-width">
                                <i
                                  className={
                                    order.order_status === "3" ||
                                    order.order_status === "43"
                                      ? `green bi bi-check-circle`
                                      : ""
                                  }
                                ></i>
                              </Col>
                              <Col className="order-status-name">
                                <span className={order.order_status_color}>
                                  {order.order_status_name}
                                </span>
                              </Col>
                              <Col className="text-center">
                                {order.order_date}
                              </Col>
                              <Col className="text-center">
                                <span
                                  className="btn bg-transparent"
                                  onClick={(e) =>
                                    handleInvoice(e, order.id_order)
                                  }
                                >
                                  <i className="bi bi-file-earmark-text"></i>
                                </span>
                              </Col>
                              <Col>
                                <span
                                  className="btn bg-transparent"
                                  onClick={(e) =>
                                    handleShippingLabel(e, order.id_order)
                                  }
                                >
                                  <i className="bi bi-box-seam"></i>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Container fluid className="p-0 d-flex flex-wrap align-items-center justify-content-center">
                            <div className="order-product-details col-md-7">
                              {order.order_details.map((orderDetail, index) => (
                                <Row
                                  className="show-product-list mb-4"
                                  key={orderDetail.id_order_detail}
                                >
                                  <Col md={1} className="offset-1">
                                    <input
                                      onClick={(e) =>
                                        handleBulkOrderDetail(
                                          e,
                                          orderDetail.id_order_detail,
                                          order.id_order,
                                          orderDetail.unit_price_tax_incl,
                                          orderDetail.product_quantity
                                        )
                                      }
                                      className="form-check-input od-checkbox"
                                      type="checkbox"
                                      value={orderDetail.id_order_detail}
                                      checked={odSelectedRows.includes(
                                        orderDetail.id_order_detail
                                      )}
                                      onChange={(e) => {}}
                                    />
                                  </Col>
                                  <Col md={1} className="p-0">
                                    <div className="product-img">
                                      <Image
                                        src={orderDetail.product_image}
                                        thumbnail
                                      />
                                    </div>
                                  </Col>
                                  <Col md={2} className="product-detail p-0">
                                    <div className="product-detail">
                                      <p>{orderDetail.product_name}</p>
                                      <p>
                                        <span>
                                          {orderDetail.product_brand} -{" "}
                                          {orderDetail.product_reference}
                                        </span>
                                      </p>
                                      <p>
                                        <span>
                                          (Taille : {orderDetail.product_size} -
                                          Couleurs : {orderDetail.product_color})
                                        </span>
                                      </p>
                                    </div>
                                  </Col>
                                  <Col md={1}>
                                    Quantité : {orderDetail.product_quantity}
                                  </Col>
                                  <Col md={1}>
                                    {orderDetail.total_price_tax_incl}
                                  </Col>
                                  <Col md={1} className="orderdetail-qty">
                                    <div className="input-group">
                                      <input
                                        className="form-control"
                                        disabled={
                                          odSelectedRows.length > 0 &&
                                          odSelectedRows.includes(
                                            orderDetail.id_order_detail
                                          )
                                            ? null
                                            : `disabled`
                                        }
                                        id={`qty_${orderDetail.id_order_detail}`}
                                        onChange={(e) =>
                                          handleOdQty(e, orderDetail)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        / {orderDetail.product_quantity}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                            {(listing === "preparation" || currentTab === 'CCVZalando') &&
                            odSelectedRows.length > 0 &&
                            odOrderId === order.id_order ? (
                              <Row className={`show-product-button signaler-button justify-content-start`}>
                                <Col md={12}>
                                  <ButtonToolbar className={`col-md-12 download-button position-relative ${showCancelButton ? 'd-none' : ''} `}>
                                    <Button onClick={handleRefundSubmit}>
                                      Signaler Indisponibilité
                                    </Button>
                                  </ButtonToolbar>
                                </Col>
                                <Col md={12}>
                                  <ButtonToolbar className={`col-md-12 cancel-button ${showCancelButton ? '' : 'd-none'}`}>
                                    <Button onClick={handleCancel}>
                                    <Spinner animation="border" className="position-relative custom-spinner"/>
                                    <div className="position-relative spinner-text">Annuler</div> 
                                    </Button>
                                  </ButtonToolbar>
                                </Col>
                              </Row>
                            ) : null}
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Container>
              </div>
            </div>
          )}
          {!isSearchActive ? (
            <PaginationBottom
              pageSize={pageSize}
              handlePageSize={handlePageSize}
              pageNumber={pageNumber}
              handlePageNumber={handlePageNumber}
              FetchOrders={FetchOrders}
            />
          ) : (
            <SearchPagination
              SearchOrders={SearchOrders}
              totalSearchOrders={totalSearchOrders}
              pageSearchSize={pageSearchSize}
              handlePageSearchSize={handlePageSearchSize}
              pageSearchNumber={pageSearchNumber}
              handlePageSearchNumber={handlePageSearchNumber}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
export default OrdersList;
