import { useSelector } from "react-redux"

export const CurrentTab = () => {
    const currentTab = useSelector((state) => state.generalData.currentTab);
    return currentTab;
}

export const CurrentUser = () => {
    const currentUser = useSelector((state) => state.auth.userName);
    return currentUser;
}

export const CurrentUserId = () => {
    const currentUserId = useSelector((state) => state.auth.userId);
    return currentUserId;
}

export const CurrentListing = () => {
    const currentListing = useSelector((state) => state.generalData.listingView);
    return currentListing;
}