const { createSlice } = require("@reduxjs/toolkit");

export const mustangSlice = createSlice({
  name: "mustang",
  initialState: {
    idWarehouse: "",
    pagination: 35,
    currentPage: 1,
    currentTab: "preparation",
    totalOrders: 0,
    totalPrepOrders: 0,
    newOrders: 0,
    newPrepOrders: 0,
    activeTab: 0,
  },
  reducers: {
    setMustangWarehouse: (state, action) => {
      state.idWarehouse = action.payload;
      localStorage.setItem("mustangwarehouse", state.idWarehouse);
    },
    setMustangPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setMustangCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setMustangCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setMustangTotalOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newOrders = 0;
      }
      if (state.totalOrders > 0) {
        let prevTotalOrders = state.totalOrders;
        state.totalOrders = action.payload;
        state.newOrders = Math.max(
          state.newOrders + (state.totalOrders - prevTotalOrders),
          0
        );
      } else {
        state.totalOrders = action.payload;
      }
    },
    setMustangTotalPrepOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newPrepOrders = 0;
      }
      if (state.totalPrepOrders > 0) {
        let prevTotalPrepOrders = state.totalPrepOrders;
        state.totalPrepOrders = action.payload;
        state.newPrepOrders = Math.max(
          state.newPrepOrders + (state.totalPrepOrders - prevTotalPrepOrders),
          0
        );
      } else {
        state.totalPrepOrders = action.payload;
      }
    },
    setMustangNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    setMustangActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetMustangState: (state, action) => {
      state.idWarehouse = "";
      state.pagination = 35;
      state.currentPage = 1;
      state.currentTab = "preparation";
      state.totalOrders = 0;
      state.totalPrepOrders = 0;
      state.newOrders = 0;
      state.newPrepOrders = 0;
      state.activeTab = 0;
    },
  },
});

export const {
  setMustangWarehouse,
  setMustangPagination,
  setMustangCurrentPage,
  setMustangCurrentTab,
  setMustangTotalOrders,
  setMustangTotalPrepOrders,
  setMustangNewOrders,
  setMustangActiveTab,
  resetMustangState,
} = mustangSlice.actions;
export const selectMustangWarehouse = (state) => state.idWarehouse;
export const selectMustangPagination = (state) => state.pagination;
export const selectMustangCurrentPage = (state) => state.currentPage;
export const selectMustangCurrentTab = (state) => state.currentTab;
export default mustangSlice.reducer;
