import { checkLogin } from "./apiHelper";

const SERVER_API =
  process.env.REACT_APP_DEV_MODE === "1"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRODUCTION_API;


const AuthChecker = async (jwtToken) => {
  const options = {
    method: "GET",
    url: `${SERVER_API}auth`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  let res = await checkLogin(options)
    .then((response) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
  return res;
};

export default AuthChecker;