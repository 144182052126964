import React, { useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { SearchContext } from "../helper/context/searchContext";
import { DateRangePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';
import 'rsuite/dist/rsuite-no-reset.min.css';

const SearchForm = () => {
  const {
    isSearchActive,
    searchText,
    handleOrderSearch,
    handleSearch,
    handleReset,
    searchLoader,
    handleSelectedDate,
    handleDateSearch,
    handleDateApply,
    handleDateReset,
    selectedRange,
  } = useContext(SearchContext);

  return (
    <Container fluid className="p-0">
      <Row>
        <Col
          md={3}
          className={
            !isSearchActive ? "searchform mt-5 mb-5" : "searchform mt-5"
          }
        >
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Rechercher"
              onChange={handleSearch}
              value={searchText}
              onKeyUp={handleSearch}
            />
            <div className="input-group-addon">
              <i className="bi bi-search" onClick={handleOrderSearch}></i>
            </div>
          </div>
        </Col>

        <Col
          md={3}
          className={
            !isSearchActive ? "searchform mt-5 mb-5" : "searchform mt-5"
          }
        >
          <DateRangePicker disabledDate={date => isAfter(date, new Date())} onChange={handleSelectedDate} placeholder="Sélectionner une plage de dates"/>
        </Col>

        {!isSearchActive ? null : (
          <Row>
            <Col md={2} className="resetSearch mb-3">
              <Form>
                <div className="resetSearchBtn">
                  <button
                    type="reset"
                    className="btn btn-link"
                    onClick={handleReset}
                  >
                    <i className="bi bi-x"></i>Réinitialiser complète
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        )}
      </Row>
    </Container>
  );
};
export default SearchForm;
