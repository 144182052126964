import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Figure } from "react-bootstrap";
import SiteLogo from "../assets/img/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/custom.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAllowedSites,
  setIsLoggedIn,
  setJwtToken,
  setUserName,
} from "../store/auth/authSlice";
import { encryptData } from "../helper/encryptionHelper";
import { checkLogin } from "../helper/apiHelper";
import { setCcvWarehouse } from "../store/site/ccv/ccvSlice";
import { setMmaxWarehouse } from "../store/site/mmax/mmaxSlice";
import { setMustangWarehouse } from "../store/site/mustang/mustangSlice";
import { setRiekerWarehouse } from "../store/site/rieker/riekerSlice";
import { persistor } from "../app/store";
import { resetGeneralData } from "../store/generaldata/generalDataSlice";
import { SearchContext } from "../helper/context/searchContext";
import { setRdhWarehouse } from "../store/site/rdh/rdhSlice";

const SERVER_API = process.env.REACT_APP_DEV_MODE === '1' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PRODUCTION_API;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Le nom d'utilisateur est requis"),
  password: Yup.string().required("Le mot de passe est requis"),
});

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

const Login = () => {
  let isAuthUser = authUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState(false);
  const initialValues = {
    username: "",
    password: "",
  };
  const {handleReset} = useContext(SearchContext);
  dispatch(resetGeneralData());
  persistor.purge();
  const handleSubmit = async (values) => {
    const username = values.username;
    const password = encryptData(values.password);
    const options = {
      method: "POST",
      url: `${SERVER_API}admin/list_users/${username}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    };
    checkLogin(options).then((response) => {
      handleReset();
      if (response.data.status === true) {
        dispatch(setUserName({name : response.data.employee.name, userId : response.data.employee.id_employee}));
        dispatch(setAllowedSites(response.data.employee.allowed_sites));
        dispatch(setIsLoggedIn(response.data.status));
        dispatch(setJwtToken(response.data.token));
        let warehouse_lists = response.data.warehouse_lists;
        let ip = response.data.ip;
        if(ip) {
          localStorage.setItem("client_ip", ip);
        }
        for (let site in warehouse_lists) {
          switch (site) {
            case "1":
              dispatch(setCcvWarehouse(warehouse_lists[site]));
              break;
            case "2":
              dispatch(setMmaxWarehouse(warehouse_lists[site]));
              break;
            case "3":
              dispatch(setMustangWarehouse(warehouse_lists[site]));
              break;
            case "4":
              dispatch(setRiekerWarehouse(warehouse_lists[site]));
              break;
            case "5":
              dispatch(setRdhWarehouse(warehouse_lists[site]));
              break;
            default:
          }
        }
        isAuthUser = true;
        navigate("/");
      } else {
        setApiError(true);
      }
    });
  };

  useEffect(() => {
    isAuthUser ? navigate("/") : navigate("/login");
    document.body.classList.add("login-layout");
    return () => {
      document.body.classList.remove("login-layout");
    };
  }, [isAuthUser, navigate]);

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <h3>
          <Figure>
            <Figure.Image width={230} height={39} alt="230x39" src={SiteLogo} />
          </Figure>
        </h3>
        {apiError && (
          <div className="invalid-feedback text-center" name="sign-in">
            Ce compte employé n'existe pas, ou le mot de passe est erroné.
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-3">
              <label htmlFor="username">Nom d'utilisateur</label>
              <Field
                type="text"
                id="username"
                name="username"
                className="form-control"
              />
              <ErrorMessage
                name="username"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password">Mot de passe</label>
              <Field
                type="password"
                id="password"
                name="password"
                className="form-control"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                SE CONNECTER
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
