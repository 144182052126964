import React, { useEffect } from "react";
import "../assets/css/custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container, Nav, Navbar, NavDropdown, Figure } from "react-bootstrap";
import SiteLogo from "../assets/img/logo-sas.svg";
import { useNavigate } from "react-router-dom";
import { logoutEmp } from "../store/auth/authSlice";
import { resetCcvState } from "../store/site/ccv/ccvSlice";
import { resetMmaxState } from "../store/site/mmax/mmaxSlice";
import { resetRiekerState } from "../store/site/rieker/riekerSlice";
import { resetMustangState } from "../store/site/mustang/mustangSlice";
import { useDispatch } from "react-redux";
import { persistor } from '../app/store';
import { resetGeneralData } from "../store/generaldata/generalDataSlice";
import { resetRdhState } from "../store/site/rdh/rdhSlice";


const authUser = () => {
  const user = localStorage.getItem("isLoggedIn") === null ? false : true;
  return user;
};

const HeaderTop = () => {

  const navigate = useNavigate();
  let isAuthUser = authUser();
  let username = localStorage.getItem("username");
  const dispatch = useDispatch();

  const handleResetAll = () => {
    dispatch(logoutEmp());
    dispatch(resetCcvState());
    dispatch(resetMmaxState());
    dispatch(resetRiekerState());
    dispatch(resetMustangState());
    dispatch(resetRdhState());
    dispatch(resetGeneralData());
    persistor.purge();
  };
  
  const handleLogOut = () => {
    let keysToRemove = ["isLoggedIn", "username", "allowedSites", "password"];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    handleResetAll();
    navigate("/login");

  };
  useEffect(() => {
    if (!isAuthUser) navigate("/login");
  }, [isAuthUser, navigate]);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#">
          <Figure>
            <Figure.Image width={230} height={39} alt="230x39" src={SiteLogo} />
          </Figure>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto "></Nav>
          <Nav style={{ maxHeight: "100px" }} navbarScroll>
            <Nav.Link href="#action1">{username}</Nav.Link>

            <NavDropdown title="" id="navbarScrollingDropdown" className="d-flex log-out">
              <NavDropdown.Item onClick={handleLogOut}>
                Log out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default HeaderTop;
