import React, { useState } from 'react';
import { Modal, Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Figure } from "react-bootstrap";
import { Container, Row, Card } from "react-bootstrap";
import SiteLogo from "../assets/img/logo.png";
import UserIcon from "../assets/img/user-popup.png";
import { logoutEmp } from "../store/auth/authSlice";
import { resetCcvState } from "../store/site/ccv/ccvSlice";
import { resetMmaxState } from "../store/site/mmax/mmaxSlice";
import { resetRiekerState } from "../store/site/rieker/riekerSlice";
import { resetMustangState } from "../store/site/mustang/mustangSlice";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from '../app/store';
import { resetGeneralData } from '../store/generaldata/generalDataSlice';
import { resetRdhState } from '../store/site/rdh/rdhSlice';

function LoginPopUpModal() {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleResetAll = () => {
    dispatch(logoutEmp());
    dispatch(resetCcvState());
    dispatch(resetMmaxState());
    dispatch(resetRiekerState());
    dispatch(resetMustangState());
    dispatch(resetRdhState());
    dispatch(resetGeneralData());
    persistor.purge();
  };
  const handleLogOut = () => {
    let keysToRemove = ["isLoggedIn", "username", "allowedSites", "password"];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    handleResetAll();
    setShow(false);
    navigate("/login");
  };
  let username = useSelector((state) => state.auth.userName);
  return (
    <>
      <Modal show={show} centered animation={true}>
        <Container>
          <Row className='align-items-center'>
            <Col md={5} className='d-flex justify-content-center pt-5 pb-md-5 px-md-0'>
              <Card className='border border-0'>
                <Card.Img className='mx-auto w-auto' src={UserIcon} />
                <Card.Body className='pb-0'>
                  <Card.Title className='fs-6'>{username}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={7} className='border-start pb-5 pt-md-5 modal-rigth-column'>
              <Modal.Header  className='border border-0 d-flex justify-content-center'>
                <Modal.Title>
                  <Figure>
                    <Figure.Image width={230} height={39} alt="230x39" src={SiteLogo} />
                  </Figure>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='d-flex justify-content-center'>
                <span className='fs-6'>Votre session a expiré !</span>
              </Modal.Body>
              <Modal.Footer className='border border-0 d-flex justify-content-center'>
                <Button variant="primary" onClick={handleLogOut} className='rounded-pill px-4 py-2'>
                Se connecter
                </Button>
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
}
export default LoginPopUpModal;
