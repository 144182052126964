import authSlice, {
  setUserName,
  setJwtToken,
  removeUserName,
  setIsLoggedIn,
  removeIsLoggedIn,
  setAllowedSites,
  selectUser,
  selectIsLoggedIn,
  selectAllowedSites,
  selectUserId,
  logoutEmp,
} from "./authSlice";

export {
  setUserName,
  setJwtToken,
  removeUserName,
  setAllowedSites,
  selectUser,
  selectIsLoggedIn,
  selectAllowedSites,
  setIsLoggedIn,
  removeIsLoggedIn,
  selectUserId,
  logoutEmp,
};

export default authSlice;
