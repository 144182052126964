import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { resetOrderSelectedRows, setCurrentTab } from "../store/generaldata/generalDataSlice";
import { CurrentTab } from "../helper/generalHelper";
import { setCcvActiveTab } from "../store/site/ccv/ccvSlice";
import { setRiekerActiveTab } from "../store/site/rieker/riekerSlice";
import { setMmaxActiveTab } from "../store/site/mmax/mmaxSlice";
import { setMustangActiveTab } from "../store/site/mustang/mustangSlice";
import { setRdhActiveTab } from "../store/site/rdh/rdhSlice";
import { SearchContext } from "../helper/context/searchContext";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { BsTextLeft } from "react-icons/bs";
import { BsXLg } from "react-icons/bs";


const LeftContent = ({ allowedSites }) => {
  const dispatch = useDispatch();
  const ccvNewOrders = useSelector((state) => state.ccv.totalPrepSiteOrders);
  const ccvSiteOrders = useSelector((state) => state.ccv.prepSiteOrders);
  const ccvZalandoOrders = useSelector((state) => state.ccv.totalZalandoOrder);
  const riekerNewOrders = useSelector((state) => state.rieker.totalPrepOrders);
  const mmaxNewOrders = useSelector((state) => state.mmax.totalPrepOrders);
  const mustangNewOrders = useSelector((state) => state.mustang.totalPrepOrders);
  const rdhNewOrders = useSelector((state) => state.rdh.totalPrepOrders);
  const currentTab = CurrentTab();
  const { setSearchActive, setSearchText } = useContext(SearchContext);
  const handleTabClicked = (selectedTab) => {
    dispatch(resetOrderSelectedRows());
    dispatch(setCurrentTab(selectedTab));
    setSearchActive(false);
    setSearchText("");
    if (selectedTab === "CCV" || selectedTab === "CCVZalando") {
      CcvActiveTab();
    }
    if (selectedTab === "Rieker") {
      RiekerActiveTab();
    }
    if (selectedTab === "Mustang") {
      MustangActiveTab();
    }
    if (selectedTab === "MMax") {
      MmaxActiveTab();
    }
    if (selectedTab === "Rdh") {
      RdhActiveTab();
    }
  };

  const CcvActiveTab = () => {
    dispatch(setCcvActiveTab(1));
    dispatch(setRiekerActiveTab(0));
    dispatch(setMmaxActiveTab(0));
    dispatch(setMustangActiveTab(0));
    dispatch(setRdhActiveTab(0));
  };
  const RiekerActiveTab = () => {
    dispatch(setCcvActiveTab(0));
    dispatch(setRiekerActiveTab(1));
    dispatch(setMmaxActiveTab(0));
    dispatch(setMustangActiveTab(0));
    dispatch(setRdhActiveTab(0));
  };
  const MmaxActiveTab = () => {
    dispatch(setCcvActiveTab(0));
    dispatch(setRiekerActiveTab(0));
    dispatch(setMmaxActiveTab(1));
    dispatch(setMustangActiveTab(0));
    dispatch(setRdhActiveTab(0));
  };
  const MustangActiveTab = () => {
    dispatch(setCcvActiveTab(0));
    dispatch(setRiekerActiveTab(0));
    dispatch(setMmaxActiveTab(0));
    dispatch(setMustangActiveTab(1));
    dispatch(setRdhActiveTab(0));
  };
  const RdhActiveTab = () => {
      dispatch(setCcvActiveTab(0));
      dispatch(setRiekerActiveTab(0));
      dispatch(setMmaxActiveTab(0));
      dispatch(setMustangActiveTab(0));
      dispatch(setRdhActiveTab(1));
  };
    

  const [toggleMenu, setToggleMenu] = useState(false);

  const ToggleSiteMenu = () => {
    setToggleMenu((prev) => !prev);
  }

  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [toggleMenu]);

  return (
    <div className="col-md-2 left-panel">
      <div className="dashboard-left-main">
        <div class={`backdrop ${toggleMenu ? `show` : ``}`} onClick={ToggleSiteMenu}></div>
        <Sidebar className={`toggle-side-bar ${toggleMenu ? `open` : ``}`}>
          <Menu>
            {allowedSites && allowedSites.includes("CCV") && (
              <SubMenu label="CCV Mode" active={currentTab === "CCV" || currentTab === "CCVZalando" ? true : false} suffix={ccvNewOrders > 0 && (
                <Badge variant="danger" shape="circle">
                  {ccvNewOrders}
                </Badge>)
              }>
                <MenuItem onClick={() => handleTabClicked("CCV")} active={currentTab === "CCV" ? true : false}> CCV Mode Site <Badge variant="danger" shape="circle"  className="inner-badge"> {ccvSiteOrders} </Badge> </MenuItem>
                <MenuItem onClick={() => handleTabClicked("CCVZalando")} active={currentTab === "CCVZalando" ? true : false}> CCV Mode Zalando <Badge variant="danger" className="inner-badge" shape="circle"> {ccvZalandoOrders} </Badge> </MenuItem>
              </SubMenu>
            )}
            {allowedSites && allowedSites.includes("Rieker") && (
              <MenuItem active={currentTab === "Rieker" ? true : false}
                onClick={() => handleTabClicked("Rieker")}> Rieker {riekerNewOrders > 0 && (
                  <Badge className="p-0">{riekerNewOrders}</Badge>
                )}</MenuItem>
            )}
            {allowedSites && allowedSites.includes("Mustang") && (
              <MenuItem active={currentTab === "Mustang" ? true : false}
                onClick={() => handleTabClicked("Mustang")}> Mustang {mustangNewOrders > 0 && (
                  <Badge className="p-0">{mustangNewOrders}</Badge>
                )}</MenuItem>
            )}
            {allowedSites && allowedSites.includes("MMax") && (
              <MenuItem active={currentTab === "MMax" ? true : false}
                onClick={() => handleTabClicked("MMax")}> Monsieur Max {mmaxNewOrders > 0 && (
                  <Badge className="p-0">{mmaxNewOrders}</Badge>
                )}</MenuItem>
            )}
            {allowedSites && allowedSites.includes("Rdh") && (
              <MenuItem active={currentTab === "Rdh" ? true : false}
                onClick={() => handleTabClicked("Rdh")}> Rdh {rdhNewOrders > 0 && (
                  <Badge className="p-0">{rdhNewOrders}</Badge>
                )}</MenuItem>
            )}
          </Menu>
        </Sidebar>
        <button className="toggle-button d-none" onClick={ToggleSiteMenu}>
          {toggleMenu ? <BsXLg /> : <BsTextLeft />}
        </button>
      </div>
    </div>
  );
};
export default LeftContent;
