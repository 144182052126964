import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../store/auth/Auth";
import ccvReducer from "../store/site/ccv/Ccv";
import mmaxReducer from "../store/site/mmax/Mmax";
import mustangReducer from "../store/site/mustang/Mustang";
import riekerReducer from "../store/site/rieker/Rieker";
import rdhReducer from "../store/site/rdh/Rdh";
import generalDataReducer from "../store/generaldata/GeneralData";
import thunk from 'redux-thunk';

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  ccv: ccvReducer,
  mmax: mmaxReducer,
  mustang: mustangReducer,
  rieker: riekerReducer,
  rdh: rdhReducer,
  generalData: generalDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store);
