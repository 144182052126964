import { useSelector } from "react-redux";

const TabNewOrderCount = (site) => {
  const ccvNewOrders = useSelector((state) => state.ccv.totalPrepOrders);
  const riekerNewOrders = useSelector((state) => state.rieker.totalPrepOrders);
  const mmaxNewOrders = useSelector((state) => state.mmax.totalPrepOrders);
  const mustangNewOrders = useSelector((state) => state.mustang.totalPrepOrders);
  const rdhNewOrders = useSelector((state) => state.rdh.totalPrepOrders);
  if (site === "CCV") {
    return ccvNewOrders;
  }
  if (site === "CCVZalando") {
    return ccvNewOrders;
  }
  if (site === "Rieker") {
    return riekerNewOrders;
  }
  if (site === "Mustang") {
    return mustangNewOrders;
  }
  if (site === "MMax") {
    return mmaxNewOrders;
  }
  if (site === "Rdh") {
    return rdhNewOrders;
  }
};

export default TabNewOrderCount;
