import ccvSlice, {
  setCcvWarehouse,
  setCcvPagination,
  setCcvCurrentPage,
  setCcvCurrentTab,
  setCcvTotalOrders,
  setCcvTotalPrepOrders,
  setCcvTotalZalandoOrders,
  setCcvTotalPrepSiteOrders,
  setCcvPrepSiteOrders,
  setCcvNewOrders,
  selectCcvWarehouse,
  selectCcvPagination,
  selectCcvCurrentPage,
  selectCcvCurrentTab,
  setCcvActiveTab,
  resetCcvState,
} from "./ccvSlice";

export {
  setCcvWarehouse,
  setCcvPagination,
  setCcvCurrentPage,
  setCcvCurrentTab,
  setCcvTotalOrders,
  setCcvTotalPrepOrders,
  setCcvTotalZalandoOrders,
  setCcvTotalPrepSiteOrders,
  setCcvPrepSiteOrders,
  setCcvNewOrders,
  selectCcvWarehouse,
  selectCcvPagination,
  selectCcvCurrentPage,
  selectCcvCurrentTab,
  setCcvActiveTab,
  resetCcvState,
};

export default ccvSlice;
