const { createSlice } = require("@reduxjs/toolkit");

export const mmaxSlice = createSlice({
  name: "mmax",
  initialState: {
    idWarehouse: "",
    pagination: 35,
    currentPage: 1,
    currentTab: "preparation",
    totalOrders: 0,
    totalPrepOrders: 0,
    newOrders: 0,
    newPrepOrders: 0,
    activeTab: 0,
  },
  reducers: {
    setMmaxWarehouse: (state, action) => {
      state.idWarehouse = action.payload;
      localStorage.setItem("mmaxwarehouse", state.idWarehouse);
    },
    setMmaxPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setMmaxCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setMmaxCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setMmaxTotalOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newOrders = 0;
      }
      if (state.totalOrders > 0) {
        let prevTotalOrders = state.totalOrders;
        state.totalOrders = action.payload;
        state.newOrders = Math.max(
          state.newOrders + (state.totalOrders - prevTotalOrders),
          0
        );
      } else {
        state.totalOrders = action.payload;
      }
    },
    setMmaxTotalPrepOrders: (state, action) => {
      if (state.activeTab === 1) {
        state.newPrepOrders = 0;
      }
      if (state.totalPrepOrders > 0) {
        let prevTotalPrepOrders = state.totalPrepOrders;
        state.totalPrepOrders = action.payload;
        state.newPrepOrders = Math.max(
          state.newPrepOrders + (state.totalPrepOrders - prevTotalPrepOrders),
          0
        );
      } else {
        state.totalPrepOrders = action.payload;
      }
    },
    setMmaxNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    setMmaxActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetMmaxState: (state, action) => {
      state.idWarehouse = "";
      state.pagination = 35;
      state.currentPage = 1;
      state.currentTab = "preparation";
      state.totalOrders = 0;
      state.totalPrepOrders = 0;
      state.newOrders = 0;
      state.newPrepOrders = 0;
      state.activeTab = 1;
    },
  },
});

export const {
  setMmaxWarehouse,
  setMmaxPagination,
  setMmaxCurrentPage,
  setMmaxCurrentTab,
  setMmaxTotalOrders,
  setMmaxTotalPrepOrders,
  setMmaxNewOrders,
  setMmaxActiveTab,
  resetMmaxState,
} = mmaxSlice.actions;
export const selectMmaxWarehouse = (state) => state.idWarehouse;
export const selectMmaxPagination = (state) => state.pagination;
export const selectMmaxCurrentPage = (state) => state.currentPage;
export const selectMmaxCurrentTab = (state) => state.currentTab;
export default mmaxSlice.reducer;
