import { useSelector } from "react-redux";

const GetWarehouseID = (site) => {
  const ccvwarehouseID = useSelector((state) => state.ccv.idWarehouse);
  const riekerwarehouseID = useSelector((state) => state.rieker.idWarehouse);
  const mustangwarehouseID = useSelector((state) => state.mustang.idWarehouse);
  const mmaxwarehouseID = useSelector((state) => state.mmax.idWarehouse);
  const rdhwarehouseID = useSelector((state) => state.rdh.idWarehouse);

  if (site === "ccv") {
    return ccvwarehouseID;
  }
  if (site === "rieker") {
    return riekerwarehouseID;
  }
  if (site === "mustang") {
    return mustangwarehouseID;
  }
  if (site === "mmax") {
    return mmaxwarehouseID;
  }
  if (site === "rdh") {
    return rdhwarehouseID;
  }
};

export default GetWarehouseID;
